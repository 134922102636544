import React from 'react'
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom'

import HomePage from './pages/Home'
import LoginPage from './pages/Login'
import OrdersPage from './pages/Orders'
import MenuPage from './pages/Menu'
import KitchenPage from './pages/Kitchen'
import UsersPage from './pages/Users'
import EventsPage from './pages/Events'
import PrinterPage from './pages/Printer'
import CateringPage from './pages/Catering'
import ReportsPage from './pages/Reports'
import ReportsCateringPage from './pages/ReportsCatering'

import EditCategoryPage from './pages/edit/Category'
import EditFoodPage from './pages/edit/Food'
import EditEventPage from './pages/edit/Event'

import './App.sass' // no need to call me sir, professor

function App () {
  return (
    <BrowserRouter>
      <Switch>
        <Route path='/' exact render={(...props) => <HomePage {...props} />} />
        <Route path='/login' exact render={(...props) => <LoginPage {...props} />} />

        <Route path='/orders' exact render={(...props) => <OrdersPage {...props} />} />
        <Route path='/menu' exact render={(...props) => <MenuPage {...props} />} />

        <Route path='/kitchen' exact render={(...props) => <KitchenPage {...props} />} />
        <Route path='/kitchen/:id' exact render={(...props) => <KitchenPage {...props} />} />

        <Route path='/edit/category/:id' exact render={(...props) => <EditCategoryPage {...props} />} />
        <Route path='/edit/food/:id' exact render={(...props) => <EditFoodPage {...props} />} />

        <Route path='/users' exact render={(...props) => <UsersPage {...props} />} />
        <Route path='/events' exact render={(...props) => <EventsPage {...props} />} />
        <Route path='/edit/event/:id' exact render={(...props) => <EditEventPage {...props} />} />

        <Route path='/catering' exact render={(...props) => <CateringPage {...props} />} />

        <Route path='/reports' exact render={(...props) => <ReportsPage {...props} />} />
        <Route path='/reports-catering' exact render={(...props) => <ReportsCateringPage {...props} />} />

        <Route path='/printer' exact render={(...props) => <PrinterPage {...props} />} />

        <Route render={(...props) => <Redirect to='/' />} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
