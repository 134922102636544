import React from 'react'

import OrdersReport from './OrdersReport'

const OrdersReportList = ({ orders, ...props } = {}) => {
  const locations = orders
    .map(order => order.config.location.name)
    .filter((v, i, a) => a.indexOf(v) === i) // deduplicate
    .sort()

  return (
    <div className='orders-report-list'>
      {
        locations.map(location => {
          const ordersHere = orders.filter(order => order.config.location.name === location)
          return (
            <div key={location} className='orders-report-list__location'>
              <h1 className='title orders-report-list__location-title'>{location}</h1>
              <OrdersReport {...props} orders={ordersHere} />
            </div>
          )
        })
      }
    </div>
  )
}

export default OrdersReportList
