import config from '../config'
import createAction from '.'

export function loadUsers (resolve) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    dispatch(createAction('users.unload'))

    const response = await window.fetch(new window.URL('/admin/list-users', config.apiUrl).href, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      console.error('unknown error', json)
      return
    }

    // console.log(json)

    dispatch(createAction('users.load', {
      list: json.users
    }))

    if (resolve) resolve(true)
  }
}

export function addUser ({ name, login, password, group }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/add-user', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          name, login, password, group
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadUsers((ok) => resolve({ ok, error: json.error })))
    }
  })

  return { action, promise }
}

export function changeUser ({ id, name, login, group }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/change-user', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id, name, login, group
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadUsers((ok) => resolve({ ok, error: json.error })))
    }
  })

  return { action, promise }
}

export function updatePassword ({ id, password }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/change-user', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id, password
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      // dispatch(loadUsers((ok) => resolve({ ok, error: json.error })))
      resolve(json)
    }
  })

  return { action, promise }
}

export function deleteUser ({ id }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/delete-user', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadUsers((ok) => resolve({ ok, error: json.error })))
    }
  })

  return { action, promise }
}
