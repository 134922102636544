import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

import { Page, withUser } from '../containers'
import { LoadingScreenWithNavbar } from '../components'

import { loadMenu } from '../actions/menu'

const SHORT_LENGTH = 60
const shorten = s => s.length < SHORT_LENGTH ? s : s.slice(0, SHORT_LENGTH) + '...'

class MenuPage extends React.Component {
  render () {
    const { loaded, categories, foods } = this.props

    if (!loaded) {
      return <LoadingScreenWithNavbar />
    }

    const categoriesById = {}
    categories.forEach(category => (categoriesById[category.id] = category))

    return (
      <Page>
        <div className='columns'>
          <div className='column'>
            {/* platters */}
            <div className='level'>
              <div className='level-left'>
                <h1 className='title'>Platter Builders</h1>
              </div>
              <div className='level-right'>
                <Link to='/edit/food/new-platter' className='button is-primary'>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  &nbsp;
                  Add New
                </Link>
              </div>
            </div>
            <table className='table is-hoverable is-striped is-fullwidth'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  foods
                    .filter(food => food.type === 'platter')
                    .sort((a, b) => {
                      if (a.categoryId === b.categoryId) return a.name.localeCompare(b.name)

                      const aCategory = categoriesById[a.categoryId]
                      const bCategory = categoriesById[b.categoryId]

                      if (aCategory.priority === bCategory.priority) return aCategory.name.localeCompare(bCategory.name)
                      return bCategory.priority - aCategory.priority
                    })
                    .map(food => {
                      return (
                        <tr key={food.id}>
                          <td>{food.name}</td>
                          <td>{(categoriesById[food.categoryId] || {}).name}</td>
                          <td>{shorten(food.description)}</td>
                          <td>
                            <Link to={`/edit/food/${food.id}`} className='button'>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>

            <div className='is-divider' />

            {/* categories */}
            <div className='level'>
              <div className='level-left'>
                <h1 className='title'>Categories</h1>
              </div>
              <div className='level-right'>
                <Link to='/edit/category/new' className='button is-primary'>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  &nbsp;
                  Add New
                </Link>
              </div>
            </div>
            <table className='table is-hoverable is-striped is-fullwidth'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Description</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  categories
                    .sort((a, b) => {
                      if (a.priority === b.priority) return a.name.localeCompare(b.name)
                      return b.priority - a.priority
                    })
                    .map(category => {
                      return (
                        <tr key={category.id}>
                          <td>{category.name}</td>
                          <td>{shorten(category.description)}</td>
                          <td>
                            <Link to={`/edit/category/${category.id}`} className='button'>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>
          </div>
          <div className='column'>
            {/* individually orderable foods */}
            <div className='level'>
              <div className='level-left'>
                <h1 className='title'>Foods</h1>
              </div>
              <div className='level-right'>
                <Link to='/edit/food/new' className='button is-primary'>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  &nbsp;
                  Add New
                </Link>
              </div>
            </div>
            <table className='table is-hoverable is-striped is-fullwidth'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Category</th>
                  <th>Description</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  foods
                    .sort((a, b) => {
                      if (a.categoryId === b.categoryId) return a.name.localeCompare(b.name)

                      const aCategory = categoriesById[a.categoryId]
                      const bCategory = categoriesById[b.categoryId]

                      if (aCategory.priority === bCategory.priority) return aCategory.name.localeCompare(bCategory.name)
                      return bCategory.priority - aCategory.priority
                    })
                    .filter(food => food.type === 'simple')
                    .map(food => {
                      return (
                        <tr key={food.id}>
                          <td>{food.name}</td>
                          <td>{(categoriesById[food.categoryId] || {}).name}</td>
                          <td>{shorten(food.description)}</td>
                          <td>
                            <Link to={`/edit/food/${food.id}`} className='button'>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faEdit} />
                              </span>
                            </Link>
                          </td>
                        </tr>
                      )
                    })
                }
              </tbody>
            </table>
          </div>
        </div>
      </Page>
    )
  }

  componentDidMount () {
    this.props.loadMenu()
  }
}

export default connect(
  state => ({
    categories: state.menu.categories,
    foods: state.menu.foods,
    items: state.menu.items,
    loaded: state.menu.loaded
  }),
  dispatch => ({
    loadMenu: () => dispatch(loadMenu())
  })
)(withUser(MenuPage))
