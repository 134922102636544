import React from 'react'
import classNames from 'classnames'

class Card extends React.Component {
  render () {
    return (
      <div className={classNames('card', this.props.classes)}>
        {
          this.props.head
            ? (
              <div className={classNames('card-header', this.props.headClasses)}>
                {this.props.head}
              </div>
            )
            : undefined
        }
        {
          this.props.image
            ? (
              <div className={classNames('card-image', this.props.imageClasses)}>
                {this.props.image}
              </div>
            )
            : undefined
        }
        <div className={classNames('card-content', this.props.innerClasses)}>
          {this.props.children}
        </div>
        {
          this.props.foot
            ? (
              <div className={classNames('card-footer', this.props.footClasses)}>
                {this.props.foot}
              </div>
            )
            : undefined
        }
      </div>
    )
  }
}

export default Card
