import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faPen } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

const EventsList = () => {
  const calendarLocation = useSelector(state => state.calendar.location)
  const selectedDate = useSelector(state => state.calendar.selected.date)
  const events = useSelector(state => state.calendar.events.list).filter(event => {
    if (calendarLocation === 'All Locations') return true

    return event.config.location.name === calendarLocation
  })

  const eventsForDay = events.filter(event => event.date === selectedDate)

  return (
    <div className='events-list'>
      {
        eventsForDay.length ? (
          eventsForDay.map(event => {
            const tagClass = {
              CLOSED: 'is-light',
              NEW: 'is-danger',
              CONFIRMED: 'is-success',
              CANCELLED: 'is-light'
            }[event.status] || 'is-warning'

            return (
              <article key={event.id} className='message is-dark'>
                <div className='message-body'>
                  <h2 className='subtitle events-list__title'>{event.name}</h2>
                  <div className='events-list__tags'>
                    <span className={classNames('tag', tagClass)}>{event.status}</span>
                    {' '}
                    <span className={classNames('tag is-light', tagClass)}>No. {event.id}</span>
                  </div>
                  <div className='events-list__preview'>
                    {event.config.guests} guests | {event.config.location.name}
                    {'\n'}
                    ${event.config.total.toFixed(2)}
                  </div>
                  <div className='events-list__buttons'>
                    <Link to={`/edit/event/${event.id}`} className='button is-primary'>
                      <FontAwesomeIcon icon={faPen} />
                      &nbsp;
                      View / Edit
                    </Link>
                  </div>
                </div>
              </article>
            )
          })
        ) : (
          <div className='events-list__placeholder'>
            No events for the selected day
          </div>
        )
      }
      <div className='events-list__add-button'>
        <Link to='/edit/event/new' className='button is-primary'>
          <FontAwesomeIcon icon={faPlusCircle} />
          &nbsp;
          Add event
        </Link>
      </div>
    </div>
  )
}

export default EventsList
