import React, { useState } from 'react'
import { faEdit, faTrash, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { NumberInputManaged, useModal } from '../components'

const FoodItemRow = ({ item, onSave, onDelete, onEditing }) => {
  const [editing, setEditing] = useState(item.id === 'new')

  const [editedState, setEditedState] = useState({
    name: item.name,
    price: item.price,
    menuActive: item.menuActive,
    platterActive: item.platterActive
  })
  const [priceActive, setPriceActive] = useState(editedState.menuActive)
  const [modal, showModal] = useModal()

  const resetEditedState = () => setEditedState({
    name: item.name,
    price: item.price,
    menuActive: item.menuActive,
    platterActive: item.platterActive
  })
  const isChanged = () => {
    if (editedState.name !== item.name) return true
    if (editedState.price !== item.price) return true
    if (editedState.menuActive !== item.menuActive) return true
    if (editedState.platterActive !== item.platterActive) return true

    return false
  }

  const startEditing = () => {
    if (onEditing) onEditing(true)
    resetEditedState()
    setEditing(true)
  }
  const cancelEditing = async () => {
    if (isChanged()) {
      const response = await showModal(
        'Cancel editing',
        `Are you sure to cancel editing '${editedState.name}'?${(item.name && editedState.name !== item.name) ? ` (formerly '${item.name}')` : ''} All your changes will be undone.`,
        [{
          text: 'Yes',
          className: 'is-danger',
          response: 'yes'
        }, {
          text: 'No',
          response: 'no',
          isCancel: true
        }]
      )

      if (response !== 'yes') return
    }

    if (onEditing) onEditing(false)
    resetEditedState()
    setEditing(false)

    if (item.id === 'new' && !item.edited) onDelete()
  }

  const setField = (name) => (event) => setEditedState({ ...editedState, [name]: event.target.value }) // yes, I'm lazy, how did you guess
  const setCheckboxField = (name) => (event) => {
    const fields = {
      [name]: event.target.checked
    }

    if (fields.menuActive === false) fields.price = 0
    if (name === 'menuActive') setPriceActive(fields.menuActive)

    setEditedState({ ...editedState, ...fields })
  }

  const saveItem = async () => {
    if (onSave) await onSave(editedState)
    resetEditedState()
    setEditing(false)
  }

  const deleteItem = async () => {
    const response = await showModal(
      'Delete item',
      `Are you sure to delete item '${item.name}'?`,
      [{
        text: 'Yes',
        className: 'is-danger',
        response: 'yes'
      }, {
        text: 'No',
        response: 'no',
        isCancel: true
      }]
    )

    if (response !== 'yes') return

    if (onDelete) await onDelete()
  }

  return (
    <tr className='food-item-row'>
      <td className='food-item-row__cell'>
        {
          editing
            ? <input className='input' type='text' value={editedState.name} onChange={setField('name')} placeholder='Name' />
            : item.name
        }
      </td>
      <td className='food-item-row__cell'>
        {
          editing
            ? <NumberInputManaged value={editedState.price} onChange={setField('price')} priceInput minValue={0} disabled={!priceActive} />
            : `$${item.price.toFixed(2)}`
        }
      </td>
      <td className='food-item-row__cell'>
        <input
          type='checkbox'
          checked={editing ? editedState.menuActive : item.menuActive}
          onChange={setCheckboxField('menuActive')}
          disabled={!editing}
        />
      </td>
      <td className='food-item-row__cell'>
        <input
          type='checkbox'
          checked={editing ? editedState.platterActive : item.platterActive}
          onChange={setCheckboxField('platterActive')}
          disabled={!editing}
        />
      </td>
      <td className='food-item-row__cell'>
        {
          editing ? (
            <>
              <button className='button is-primary' onClick={saveItem}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faSave} />
                </span>
                &nbsp;
                Save
              </button>
              &nbsp;
              <button className='button is-warning' onClick={cancelEditing}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                &nbsp;
                Cancel
              </button>
            </>
          ) : (
            <>
              <button className='button' onClick={startEditing}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faEdit} />
                </span>
                &nbsp;
                Edit
              </button>
              &nbsp;
              <button className='button is-danger' onClick={deleteItem}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faTrash} />
                </span>
                &nbsp;
                Delete
              </button>
            </>
          )
        }
        {modal}
      </td>
    </tr>
  )
}

export default FoodItemRow
