import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSave, faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import { Page, withUser } from '../containers'
import { LoadingScreenWithNavbar, UserRow, useModal, usePasswordInput } from '../components'

import { loadUsers, addUser } from '../actions/users'

const groups = {
  admin: 'Administrator',
  manager: 'Kitchen Manager'
}

const UsersPage = () => {
  const dispatch = useDispatch()

  const users = useSelector(state => state.users.list)
  const usersLoaded = useSelector(state => state.users.loaded)

  const [newUserPopup, setNewUserPopup] = useState(false)
  const [newUserLoading, setNewUserLoading] = useState(false)
  const [newUserState, setNewUserState] = useState({
    name: '',
    login: '',
    group: groups[Object.keys(groups)[0]]
  })
  const [passwordInput, getPassword, resetPassword] = usePasswordInput()

  const [modal, showModal] = useModal()

  useEffect(() => {
    if (!usersLoaded) dispatch(loadUsers())
  }, [usersLoaded, dispatch])

  if (!usersLoaded) return <LoadingScreenWithNavbar />

  const resetNewUser = () => {
    resetPassword()
    setNewUserLoading(false)
    setNewUserState({
      name: '',
      login: '',
      group: Object.keys(groups)[0]
    })
  }

  const newUserReady = newUserState.name.length >= 4 && newUserState.login.length && getPassword().ready

  const startNewUser = () => {
    resetNewUser()
    setNewUserPopup(true)
  }
  const cancelNewUser = () => {
    setNewUserPopup(false)
    resetNewUser()
  }
  const saveNewUser = async () => {
    if (!newUserReady) return console.error('New user not ready yet (how did you even call this?)')

    const newUser = {
      name: newUserState.name,
      login: newUserState.login,
      password: getPassword().password,
      group: newUserState.group
    }

    setNewUserLoading(true)

    const { action, promise } = addUser(newUser)
    dispatch(action)
    const { ok, error } = await promise

    if (ok) {
      resetNewUser()
      setNewUserPopup(false)
    } else {
      await showModal(
        'Error',
        `Unknown error while saving user: ${error}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setNewUserLoading(false)
    }
  }

  const truncateLogin = str => str.toLowerCase().split('').filter(c => /[a-z0-9_-]/.test(c)).join('')

  const setName = (event) => setNewUserState({ ...newUserState, name: event.target.value })
  const setLogin = (event) => setNewUserState({ ...newUserState, login: truncateLogin(event.target.value) })
  const setGroup = (event) => setNewUserState({ ...newUserState, group: event.target.value })

  return (
    <Page>
      <h1 className='title'>Users</h1>
      <table className='table is-striped is-fullwidth'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Login name</th>
            <th>Group</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            users.map(user => <UserRow user={user} key={user.id} />)
          }
        </tbody>
      </table>
      <button className='button is-primary' onClick={startNewUser}>
        <span className='icon'>
          <FontAwesomeIcon icon={faPlusCircle} />
        </span>
        &nbsp;
        Add user
      </button>
      <div className={classNames('modal', {
        'is-active': newUserPopup
      })}
      >
        <div className='modal-background' onClick={cancelNewUser} />
        <div className='modal-card'>
          <header className='modal-card-head'>
            <p className='modal-card-title'>Add new user</p>
            <button className='delete' aria-label='close' onClick={cancelNewUser} />
          </header>
          <section className='modal-card-body'>
            <div className='field'>
              <label className='label'>Name</label>
              <div className='control'>
                <input className='input' placeholder='Actual Name' value={newUserState.name} onChange={setName} />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Login</label>
              <div className='control'>
                <input className='input' placeholder='Login ID' value={newUserState.login} onChange={setLogin} />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Group</label>
              <div className='select'>
                <select value={newUserState.group} onChange={setGroup}>
                  {
                    Object.keys(groups).map(key => {
                      const name = groups[key]
                      return <option key={key} value={key}>{name}</option>
                    })
                  }
                </select>
              </div>
            </div>
            {passwordInput}
          </section>
          <footer className='modal-card-foot'>
            <button
              className={classNames('button is-success', {
                'is-loading': newUserLoading
              })}
              disabled={!newUserReady || newUserLoading}
              onClick={saveNewUser}
            >
              <span className='icon'>
                <FontAwesomeIcon icon={faSave} />
              </span>
              &nbsp;
              Save user
            </button>
            &nbsp;
            <button className='button is-warning' onClick={cancelNewUser}>
              <span className='icon'>
                <FontAwesomeIcon icon={faTimesCircle} />
              </span>
              &nbsp;
              Cancel
            </button>
          </footer>
        </div>
      </div>
      {modal}
    </Page>
  )
}

export default withUser(UsersPage)
