import React from 'react'
import xkcd1179 from '../util/xkcd1179'

const readableOrderStatus = (status) => {
  return {
    COMPLETE: 'Complete',
    CONFIRMED: 'Paid',
    DEFERRED: 'Payment at Pickup',
    UNCONFIRMED: 'Unconfirmed',
    CANCELED: 'Canceled',
    UNFINISHED: 'Unfinished'
  }[status] || 'Unknown'
}
const readableCateringStatus = (status) => ({
  NEW: 'Quote',
  CONFIRMED: 'Contacted',
  PAID: 'Paid',
  CLOSED: 'Closed'
})[status] || 'Quote'

const OrdersReport = ({ orders, isMonth, isCatering }) => {
  const firstOrder = orders.length ? xkcd1179(new Date(orders[0].createdAt)) : xkcd1179(new Date())
  const lastOrder = orders.length ? xkcd1179(new Date(orders.slice(-1)[0].createdAt)) : xkcd1179(new Date())

  const total = {
    items: 0,
    subtotal: 0,
    tax: 0,
    tip: 0,
    total: 0
  }
  orders.forEach(order => {
    total.items += order.config.items.length
    total.subtotal += Math.round(Number(order.config.subtotal) * 100) // calculate in cents first
    total.tax += Math.round(Number(order.config.tax) * 100)
    total.tip += Math.round(Number(order.config.tip) * 100)
    total.total += Math.round(Number(isCatering ? order.config.total : order.config.price) * 100)
  })

  total.subtotal /= 100 // turn cents back to dollars
  total.tax /= 100
  total.tip /= 100
  total.total /= 100

  const totalRow = (
    <tr className='orders-report__total-row'>
      <td colSpan={5} />
      {/* <td className='orders-report__price-field'>{total.items} items</td> */}
      <td className='orders-report__price-field'>${total.subtotal.toFixed(2)}</td>
      <td className='orders-report__price-field'>${total.tax.toFixed(2)}</td>
      <td className='orders-report__price-field'>${total.tip.toFixed(2)}</td>
      <td className='orders-report__price-field'>${total.total.toFixed(2)}</td>
      {/*                           ^ I hella love that classname */}
    </tr>
  )

  if (firstOrder.slice(0, 7) !== lastOrder.slice(0, 7)) {
    const months = {}

    orders.forEach(order => {
      const month = xkcd1179(new Date(order.createdAt)).slice(0, 7)
      if (!months[month]) months[month] = []
      months[month].push(order)
    })

    return (
      <>
        {
          Object.keys(months).sort((a, b) => b.localeCompare(a)).map(month => {
            const ordersInMonth = months[month].sort((a, b) => b.createdAt.localeCompare(a.createdAt))
            return <OrdersReport key={month} isMonth orders={ordersInMonth} isCatering={isCatering} />
          })
        }
        <table className='table is-fullwidth is-striped is-hoverable'>
          <tbody>
            {totalRow}
          </tbody>
        </table>
      </>
    )
  }

  return (
    <table className='table is-fullwidth is-striped is-hoverable'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Order</th>
          <th>Name</th>
          <th>Status</th>
          <th className='orders-report__price-field'>Items</th>
          <th className='orders-report__price-field'>Sub-Total</th>
          <th className='orders-report__price-field'>Tax</th>
          <th className='orders-report__price-field'>Tip</th>
          <th className='orders-report__price-field'>Total</th>
        </tr>
      </thead>
      <tbody>
        {
          orders.map(order => {
            return (
              <tr key={order.id}>
                <td>
                  {new Date(order.createdAt).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric' })}
                </td>
                <td># {order.id}</td>
                <td>{isCatering ? order.config.name.first + ' ' + order.config.name.last : order.config.name}</td>
                <td>{(isCatering ? readableCateringStatus : readableOrderStatus)(order.status)}</td>
                <td className='orders-report__price-field'>{order.config.items.length}</td>
                <td className='orders-report__price-field'>{Number(order.config.subtotal).toFixed(2)}</td>
                <td className='orders-report__price-field'>{Number(order.config.tax).toFixed(2)}</td>
                <td className='orders-report__price-field'>{Number(order.config.tip).toFixed(2)}</td>
                <td className='orders-report__price-field'>${Number(isCatering ? order.config.total : order.config.price).toFixed(2)}</td>
              </tr>
            )
          })
        }
        <tr className='orders-report__spacer-row'>
          <td colSpan={9} />
        </tr>
        <tr className='orders-report__total-row'>
          <td>Summary for {isMonth ? 'month' : 'date range'}:</td>
          <td>{orders.length} orders</td>
          <td colSpan={3} />
          <td className='orders-report__price-field'>Sub-Total</td>
          <td className='orders-report__price-field'>Tax</td>
          <td className='orders-report__price-field'>Tip</td>
          <td className='orders-report__price-field'>Total</td>
        </tr>
        {totalRow}
      </tbody>
    </table>
  )
}

export default OrdersReport
