import xkcd1179 from '../util/xkcd1179'

const today = new Date()

const initialState = {
  selected: {
    year: today.getFullYear(),
    month: today.getMonth(),
    date: xkcd1179(today)
  },
  events: {
    list: [],
    loaded: true
  },
  filters: {
    statuses: []
  },
  location: 'All Locations'
}

const calendarReducer = (state = initialState, action) => {
  const type = action.type // eslint-disable-line no-unused-vars

  if (type === 'calendar.loadEvents') return { ...state, events: { ...state.events, list: action.list, loaded: true } }
  if (type === 'calendar.unloadEvents') return { ...state, events: { ...state.events, list: [], loaded: false } }

  if (type === 'calendar.selectMonth') {
    const date = xkcd1179(new Date(action.year, action.month, 1))
    return {
      ...state,
      selected: {
        ...state.selected,
        year: action.year,
        month: action.month,
        date
      }
    }
  }
  if (type === 'calendar.selectDate') return { ...state, selected: { ...state.selected, date: xkcd1179(new Date(action.date + ' 0:00')) } }

  if (type === 'calendar.filterStatuses') return { ...state, filters: { ...state.filters, statuses: action.statuses } }

  if (type === 'calendar.setLocation') return { ...state, location: action.location }

  return state
}

export default calendarReducer
