import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Page, withUser } from '../containers'
import { EventsCalendar, EventsList, FilteredEventsList } from '../components'

const EventsPage = () => {
  const selected = useSelector(state => state.calendar.selected)
  const selectedDate = new Date(selected.date + ' 0:00')

  const [isFiltering, setFiltering] = useState(false)

  return (
    <Page topAligned>
      {
        isFiltering ? (
          <FilteredEventsList onExit={() => setFiltering(false)} />
        ) : (
          <div className='events-page__body'>
            <EventsCalendar onFilter={() => setFiltering(true)} />
            <div className='events-page__event-list'>
              <h1 className='title'>
                {selectedDate.toLocaleDateString('en-US', {
                  weekday: 'short',
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })}
              </h1>
              <EventsList />
            </div>
          </div>
        )
      }
    </Page>
  )
}

export default withUser(EventsPage)
