const initialState = {
  list: {}
}

const configsReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'configs.set') return { ...state, list: { ...state.list, [action.config.name]: action.config } }
  if (type === 'configs.unload') {
    const newList = { ...state.list }
    delete newList[action.name]

    return { ...state, list: newList }
  }

  return state
}

export default configsReducer
