import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect, useParams, useHistory } from 'react-router-dom'

import { Page, withUser } from '../../containers'
import { LoadingScreenWithNavbar, useModal, NumberInputManaged } from '../../components'

import { createCategory, updateCategory, deleteCategory } from '../../actions/menu'

const EditCategoryPage = ({ loaded, categories, dispatch }) => {
  const params = useParams()
  const id = isNaN(params.id) ? params.id : Number(params.id)

  const history = useHistory()
  const [modal, showModal] = useModal()

  const category = categories.filter(c => c.id === id)[0] || null

  const [name, setName] = useState(category ? category.name : '')
  const [description, setDescription] = useState(category ? category.description : '')
  const [priority, setPriority] = useState(category ? category.priority : 0)

  if (!loaded) return <LoadingScreenWithNavbar />
  if (!category && id !== 'new') {
    return (
      <Page>
        <Redirect to='/menu' />
      </Page>
    )
  }

  const submit = async () => {
    const { action, promise } = id === 'new'
      ? createCategory({ name, description, priority })
      : updateCategory({ id, name, description, priority })

    dispatch(action)
    const ok = await promise

    if (ok) history.push('/menu')
  }

  const deleteItem = async () => {
    if (id === 'new') return

    const response = await showModal(
      'Delete Category',
      `Are you sure you want to delete category '${name}'?`,
      [{
        text: 'Yes',
        className: 'is-danger',
        response: 'yes'
      }, {
        text: 'No',
        response: 'no',
        isCancel: true
      }]
    )
    if (response !== 'yes') return

    const { action, promise } = deleteCategory({ id })

    dispatch(action)
    const ok = await promise

    if (ok) history.push('/menu')
  }

  return (
    <Page>
      <h1 className='title'>{
        id === 'new'
          ? 'Add new category'
          : `Edit category: ${category.name}`
      }
      </h1>

      <div className='field'>
        <label className='label'>Name</label>
        <div className='control'>
          <input className='input' type='text' placeholder='Category name' value={name} onChange={(event) => setName(event.target.value)} />
        </div>
      </div>

      <div className='field'>
        <label className='label'>Description</label>
        <div className='control'>
          <textarea className='textarea' placeholder='Category description' value={description} onChange={(event) => setDescription(event.target.value)} />
        </div>
      </div>

      <div className='field'>
        <label className='label'>Priority</label>
        <div className='control'>
          <NumberInputManaged placeholder='Priority' value={priority} onChange={(event) => setPriority(event.target.value)} />
        </div>
        <p className='help'>
          Priority controls where the category shows up in the ordering system, higher priorities are shown before lower ones.
          If two categories have the same priority, they are ordered alphabetically.
        </p>
      </div>

      <div className='is-divider' />

      <div className='level'>
        <div className='level-left'>
          {
            id === 'new'
              ? undefined
              : (
                <>
                  <div className='field is-grouped'>
                    <p className='control'>
                      <button className='button is-danger' onClick={deleteItem}>Delete</button>
                    </p>
                  </div>
                </>
              )
          }
        </div>
        <div className='level-right'>
          <div className='field is-grouped is-grouped-right'>
            <p className='control'>
              <Link to='/menu' className='button'>Cancel</Link>
            </p>
            <p className='control'>
              <button className='button is-primary' onClick={submit}>Submit</button>
            </p>
          </div>
        </div>
      </div>

      {modal}

      {/* {
        id === 'new'
          ? undefined
          : <>
            <div className='is-divider' data-content='DANGER ZONE' />

            <div className='field is-grouped'>
              <p className='control'>
                <button className='button is-danger'>Delete</button>
              </p>
            </div>
          </>
      } */}
    </Page>
  )
}

export default connect(
  state => ({
    categories: state.menu.categories,
    loaded: state.menu.loaded
  }),
  dispatch => ({
    dispatch: (action) => dispatch(action)
  })
)(withUser(EditCategoryPage))
