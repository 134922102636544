import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp, faAngleRight, faCreditCard, faTrash, faEnvelope, faCopy, faPrint } from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import config from '../config'
import { useModal, usePrinter, NumberInputManaged, DateInput } from '../components'

import xkcd1179 from '../util/xkcd1179'

import createAction from '../actions'

const readableStatus = (status) => ({
  // NEW: 'Quote',
  // CONFIRMED: 'Contacted',
  // PAID: 'Paid',
  // CLOSED: 'Closed',
  '02_NEW': 'Quote',
  '03_CONFIRMED': 'Contacted',
  '04_PAID': 'Paid',
  '01_CLOSED': 'Closed'
})[status] || 'Quote'

const CateringOrder = ({ order, isPrint, isKitchenSheet }) => {
  const dispatch = useDispatch()

  const [expanded, setExpanded] = useState(Boolean(isPrint))
  const toggle = () => setExpanded(!expanded)

  const token = useSelector(state => state.user.token)
  const locations = useSelector(state => state.orders.locations.list)

  const [modal, showModal] = useModal()
  const [duplicating, setDuplicating] = useState(false)
  const [resending, setResending] = useState(false)
  const [settingStatus, setSettingStatus] = useState(false)
  const [deleting, setDeleting] = useState(false)

  const today = xkcd1179(new Date())

  const updateOrderConfig = async (newConfig) => {
    setSettingStatus(true)

    const response = await window.fetch(new window.URL('/admin/update-catering-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id: order.id,
        config: newConfig
      })
    })
    const json = await response.json()

    if (json.ok) dispatch(createAction('catering.update-config', { id: order.id, config: newConfig }))

    setSettingStatus(false)
  }

  const logPayment = async () => {
    const payment = {
      amount: 0,
      date: today,
      type: 'Credit Card'
    }

    const sure = await showModal(
      'Log Payment',
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Amount</label>
            <NumberInputManaged defaultValue={0} onChange={event => (payment.amount = event.target.value)} buttonsHidden />
          </div>
          <div className='field'>
            <label className='label'>Type</label>
            <div className='select'>
              <select defaultValue='Credit Card' onChange={event => (payment.type = event.target.value)}>
                <option value='Credit Card'>Credit Card</option>
                <option value='Cash'>Cash</option>
                <option value='Check'>Check</option>
              </select>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Date</label>
            <DateInput defaultValue={order.date} inline onChange={event => (payment.date = xkcd1179(event.target.value))} />
          </div>
        </div>
      </div>,
      [{
        text: 'Cancel',
        isCancel: true,
        response: 'no'
      }, {
        text: 'Log Payment',
        className: 'is-success',
        response: 'yes'
      }]
    )

    if (sure !== 'yes') return

    const existingPayments = order.config.payments || []

    const payments = [
      ...existingPayments,
      payment
    ]

    updateOrderConfig({
      ...order.config,
      payments
    })
  }

  const removePayment = (index) => () => {
    const payments = [...order.config.payments]
    payments.splice(index, 1)

    updateOrderConfig({
      ...order.config,
      payments
    })
  }

  const duplicateOrder = async (id) => {
    setDuplicating(true)

    const response = await window.fetch(new window.URL('/admin/duplicate-catering-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id
      })
    })
    const json = await response.json()

    setDuplicating(false)

    showModal(
      'Duplicate Order',
      'Would you like to duplicate this order?',
      [{
        text: 'Duplicate',
        className: 'is-success',
        url: `https://order.andalousgrill.com/catering/clone/${encodeURIComponent(json.token)}`
      }, {
        text: 'Cancel',
        className: 'is-danger',
        isCancel: true
      }]
    )
  }

  const deleteOrder = async (id) => {
    const response = await showModal(
      'Delete Order',
      'Are you sure you would like to delete this order?',
      [{
        text: 'Yes, delete it',
        className: 'is-danger',
        response: 'yes'
      }, {
        text: 'No, cancel',
        // className: 'is-light',
        isCancel: true
      }]
    )

    if (response !== 'yes') return

    setDeleting(true)

    const reply = await window.fetch(new window.URL('/admin/delete-catering-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id
      })
    })
    const json = await reply.json()

    setDeleting(false)
    if (!json.ok) {
      console.error('error while deleting order', json)
    }

    dispatch(createAction('catering.deleteOrder', { id }))
  }

  const resendEmail = async (id) => {
    const response = await showModal(
      'Resend Email',
      'Are you sure you would like to resend the email for this order?',
      [{
        text: 'Yes',
        className: 'is-success',
        response: 'yes'
      }, {
        text: 'No',
        className: 'is-danger',
        isCancel: true
      }]
    )

    if (response !== 'yes') return

    setResending(true)

    await window.fetch(new window.URL('/admin/trigger-catering-email', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id
      })
    })

    setResending(false)

    await showModal(
      'Resend Email',
      'Email has been resent successfully'
    )
  }

  const setStatus = (id, status) => async () => {
    setSettingStatus(true)

    const response = await window.fetch(new window.URL('/admin/update-catering-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id,
        status
      })
    })
    const json = await response.json()

    if (json.ok) dispatch(createAction('catering.update-status', { id, status }))

    setSettingStatus(false)
  }

  const toggleTaxExempt = async () => {
    const taxRate = (locations.filter(location => location.id === order.config.location.id)[0] || {}).taxRate || 8.25
    const taxExempt = !order.config.taxExempt

    const tax = taxExempt ? 0 : order.config.subtotal * taxRate / 100
    const total = order.config.subtotal + tax + order.config.tip

    const newConfig = { ...order.config, taxExempt, tax, total }

    const response = await window.fetch(new window.URL('/admin/update-catering-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id: order.id,
        config: newConfig
      })
    })
    const json = await response.json()

    if (json.ok) dispatch(createAction('catering.update-config', { id: order.id, config: newConfig }))
  }

  const [printer, doPrint] = usePrinter()

  const printInvoice = () => doPrint(<CateringOrder order={order} isPrint />)
  const printKitchenSheet = () => doPrint(<CateringOrder order={order} isPrint isKitchenSheet />)

  const mainContent = (
    <>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Name:</div>
          <div className='catering-order__field-text'>{order.config.name.first} {order.config.name.last}</div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Order number:</div>
          <div className='catering-order__field-text'>{order.id}</div>
        </div>
        <div className='catering-order__field catering-order__field--double'>
          <div className='catering-order__field-title'>Fulfillment:</div>
          <div className='catering-order__field-text'>
            <strong>{order.config.fulfillment} / {order.config.location.name} / {new Date(order.config.date).toLocaleString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            }) || '\xa0'}, {order.config.time}
            </strong>
          </div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Email:</div>
          <div className='catering-order__field-text'>{order.config.email}</div>
        </div>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Phone:</div>
          <div className='catering-order__field-text'>
            <a href={`tel:${order.config.phone.replace(/\D/g, '')}`}>
              {order.config.phone}
            </a>
          </div>
        </div>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Company:</div>
          <div className='catering-order__field-text'>{order.config.company || '\xa0'}</div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Address 1:</div>
          <div className='catering-order__field-text'>{order.config.address.line1 || '\xa0'}</div>
        </div>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Address 2:</div>
          <div className='catering-order__field-text'>{order.config.address.line2 || '\xa0'}</div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>City:</div>
          <div className='catering-order__field-text'>{order.config.address.city || '\xa0'}</div>
        </div>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Zip:</div>
          <div className='catering-order__field-text'>{order.config.address.zip || '\xa0'}</div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Comments:</div>
          <div className='catering-order__field-text catering-order__field-text--comment'>{order.config.comment || '\xa0'}</div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Order Created:</div>
          <div className='catering-order__field-text'>{new Date(order.createdAt).toLocaleString('en-us', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          }) || '\xa0'}
          </div>
        </div>
      </div>
      <div className='catering-order__row'>
        <div className='catering-order__field'>
          <div className='catering-order__field-title'>Order:</div>
        </div>
      </div>
      <table className='table is-striped is-hoverable is-fullwidth catering-order__field-table'>
        <thead>
          <tr>
            <th>Qty</th>
            <th>Name</th>
            {
              isKitchenSheet ? (
                <th />
              ) : (
                <>
                  <th>Price</th>
                  <th>Total</th>
                </>
              )
            }
          </tr>
        </thead>
        <tbody>
          {
            order.config.items.map((item, index) => (
              <tr key={index}>
                <td>{item.quantity}</td>
                <td>
                  {item.name}
                  {
                    item.type === 'lunchbox' ? (
                      <div className='catering-order__lunchbox-items'>
                        {
                          item.items.map((subItem, index) => (
                            <div className='catering-order__lunchbox-item' key={index}>{subItem.name}</div>
                          ))
                        }
                      </div>
                    ) : undefined
                  }
                </td>
                {
                  isKitchenSheet ? (
                    <td className='catering-order__square-column'>
                      <div className='catering-order__square' />
                      <div className='catering-order__square' />
                    </td>
                  ) : (
                    <>
                      <td>${Number(item.price / item.quantity).toFixed(2)}</td>
                      <td>${Number(item.price).toFixed(2)}</td>
                    </>
                  )
                }
              </tr>
            ))
          }
          {
            isKitchenSheet ? undefined : (
              <>
                <tr>
                  <td colSpan={4} />
                </tr>
                <tr className='catering-order__total-row'>
                  <td colSpan={2} />
                  <td>Subtotal:</td>
                  <td>${Number(order.config.subtotal).toFixed(2)}</td>
                </tr>
                <tr className='catering-order__total-row'>
                  <td colSpan={2} />
                  <td>Tax:</td>
                  <td>${Number(order.config.tax).toFixed(2)}</td>
                </tr>
                <tr className='catering-order__total-row'>
                  <td colSpan={2} />
                  <td>Tip:</td>
                  <td>${Number(order.config.tip).toFixed(2)}</td>
                </tr>
                <tr className='catering-order__total-row'>
                  <td colSpan={2} />
                  <td>Total:</td>
                  <td>${Number(order.config.total).toFixed(2)}</td>
                </tr>
              </>
            )
          }
        </tbody>
      </table>
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Payments Received:</label>
            <table className='table is-striped is-fullwidth is-hoverable'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {
                  (order.config.payments || []).map((payment, index) => (
                    <tr key={index}>
                      <td>{new Date(payment.date).toLocaleDateString('en-us', { year: 'numeric', day: 'numeric', month: 'short', timeZone: 'utc' })}</td>
                      <td>${payment.amount}</td>
                      <td>{payment.type || 'Unknown'}</td>
                      <td className='has-text-right'>
                        {
                          isPrint ? ' ' : (
                            <button className='button is-small' onClick={removePayment(index)}>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </button>
                          )
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {
        isPrint ? undefined : (
          <>
            <div className='catering-order__row'>
              <div className='catering-order__field'>
                <div className='field'>
                  <label className='checkbox'>
                    <input type='checkbox' checked={order.config.taxExempt || false} onChange={toggleTaxExempt} />
                    &nbsp;
                    Tax exempt
                  </label>
                </div>
              </div>
              <div className='catering-order__field catering-order__field--buttons'>
                <button className='button catering-order__button' onClick={printKitchenSheet}>
                  <FontAwesomeIcon icon={faPrint} />
                  &nbsp;
                  Print Kitchen Worksheet
                </button>
                <button className='button catering-order__button' onClick={printInvoice}>
                  <FontAwesomeIcon icon={faPrint} />
                  &nbsp;
                  Print Invoice
                </button>
              </div>
            </div>
            <div className='catering-order__row catering-order__row--buttons'>
              <div className='catering-order__field catering-order__field--buttons'>
                <button
                  className={classNames('button catering-order__status-button', {
                    'is-loading': settingStatus,
                    'is-danger': order.status === '02_NEW'
                  })} onClick={setStatus(order.id, '02_NEW')} disabled={settingStatus}
                >
                  Quote
                </button>
                <FontAwesomeIcon icon={faAngleRight} className='catering-order__status-separator' />
                <button
                  className={classNames('button catering-order__status-button', {
                    'is-loading': settingStatus,
                    'is-warning': order.status === '03_CONFIRMED'
                  })} onClick={setStatus(order.id, '03_CONFIRMED')} disabled={settingStatus}
                >
                  Contacted
                </button>
                <FontAwesomeIcon icon={faAngleRight} className='catering-order__status-separator' />
                <button
                  className={classNames('button catering-order__status-button', {
                    'is-loading': settingStatus,
                    'is-success': order.status === '04_PAID'
                  })} onClick={setStatus(order.id, '04_PAID')} disabled={settingStatus}
                >
                  Paid
                </button>
                <FontAwesomeIcon icon={faAngleRight} className='catering-order__status-separator' />
                <button
                  className={classNames('button catering-order__status-button', {
                    'is-loading': settingStatus,
                    'is-light': order.status === '01_CLOSED'
                  })} onClick={setStatus(order.id, '01_CLOSED')} disabled={settingStatus}
                >
                  Closed
                </button>
              </div>
              <div className='catering-order__field catering-order__field--buttons'>
                <button className='button catering-order__button' onClick={logPayment}>
                  <span className='icon'>
                    <FontAwesomeIcon icon={faCreditCard} />
                  </span>
                  <span>
                    Log Payments
                  </span>
                </button>
                <button
                  className={classNames('button catering-order__button', {
                    'is-loading': resending
                  })} onClick={() => resendEmail(order.id)} disabled={resending}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                  &nbsp;
                  Email
                </button>
                <button
                  className={classNames('button catering-order__button', {
                    'is-loading': duplicating
                  })} onClick={() => duplicateOrder(order.id)} disabled={duplicating}
                >
                  <FontAwesomeIcon icon={faCopy} />
                  &nbsp;
                  Duplicate
                </button>
                <button
                  className={classNames('button catering-order__button is-danger', {
                    'is-loading': deleting
                  })} onClick={() => deleteOrder(order.id)} disabled={deleting}
                >
                  <FontAwesomeIcon icon={faTrash} />
                  &nbsp;
                  Delete
                </button>
              </div>
            </div>
          </>
        )
      }
      {/* <pre><code>
          {JSON.stringify(order.config, null, 2)}
        </code></pre> */}
    </>
  )

  if (isPrint) {
    const address = order.config.location.config.address

    return (
      <div className='catering-order__print'>
        <div className='catering-order__print-header'>
          <img className='catering-order__print-logo' src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-500w.png' alt='' />
        </div>
        {
          (address && !isKitchenSheet) ? (
            <div className='catering-order__print-address'>
              <div className='catering-order__print-address-line'>{address.line1}</div>
              {
                address.line2 ? (
                  <div className='catering-order__print-address-line'>{address.line2}</div>
                ) : undefined
              }
              <div className='catering-order__print-address-line'>{address.cityLine}</div>
              <div className='catering-order__print-address-line'>ph: {order.config.location.phoneNumber}</div>
              {
                address.fax ? (
                  <div className='catering-order__print-address-line'>fx: {address.fax}</div>
                ) : undefined
              }
              <div className='catering-order__print-address-line catering-order__print-address-thank-you-line'>
                Thank you for your order
              </div>
            </div>
          ) : undefined
        }
        {mainContent}
      </div>
    )
  }

  return (
    <div
      className={classNames('message catering-order', {
        'catering-order--expanded': expanded,
        'catering-order--collapsed': !expanded,
        'is-danger': order.status === '02_NEW',
        'is-light': order.status === '01_CLOSED',
        'is-warning': order.status === '03_CONFIRMED',
        'is-success': order.status === '04_PAID'
      })} key={order.id}
    >
      <div className='message-header catering-order__header' onClick={toggle}>
        <p>
          <span className='tag is-dark'>{readableStatus(order.status)}</span>
        </p>
        <p>
          #{order.id}
          {' - '}
          {order.config.name.first} {' '}
          {order.config.name.last} {' '}
          {
            order.config.company ? `at ${order.config.company}` : ''
          }
          {' - '}
          {order.config.fulfillment}
          {' - '}
          {order.config.location.name}
          {' - '}
          {new Date(order.config.date).toLocaleString('en-us', {
            month: 'short',
            day: 'numeric',
            weekday: 'short'
          })} {order.config.time}
          {/* {`$${order.config.total.toFixed(2)}`} */}
        </p>
        <div className='button is-small is-dark'>
          <span className='icon'>
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
          </span>
        </div>
      </div>
      <div className='message-body catering-order__body'>
        {mainContent}
      </div>
      {modal}
      {printer}
    </div>
  )
}

export default CateringOrder
