import config from '../config'
import createAction from '.'

import xkcd1179 from '../util/xkcd1179'

export function loadCalendarEvents (resolve) {
  return async (dispatch, getState) => {
    const token = getState().user.token
    const { year, month } = getState().calendar.selected

    const cursor = new Date(year, month, 1).toISOString()

    dispatch(createAction('calendar.unloadEvents'))

    const response = await window.fetch(new window.URL('/admin/list-calendar-events', config.apiUrl).href, {
      method: 'post',
      body: JSON.stringify({
        cursor
      }),
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      // console.error('unknown error', json)
      return
    }

    // console.log(json)

    const list = json.calendarEvents.map(event => ({ ...event, date: xkcd1179(new Date(event.date.slice(0, 10).replace(/-/g, '/') + ' 0:00')) }))

    dispatch(createAction('calendar.loadEvents', { list, cursor: json.cursor }))

    if (resolve) resolve({ ok: true })
  }
}

export function loadFilteredCalendarEvents (resolve) {
  return async (dispatch, getState) => {
    const token = getState().user.token
    const filters = getState().calendar.filters
    // const { year, month } = getState().calendar.selected

    // const cursor = new Date(year, month, 1).toISOString()

    dispatch(createAction('calendar.unloadEvents'))

    const response = await window.fetch(new window.URL('/admin/list-calendar-events', config.apiUrl).href, {
      method: 'post',
      body: JSON.stringify({
        statusFilter: filters.statuses,
        reverse: true
      }),
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      // console.error('unknown error', json)
      return
    }

    // console.log(json)

    const list = json.calendarEvents.map(event => ({ ...event, date: xkcd1179(new Date(event.date.slice(0, 10).replace(/-/g, '/') + ' 0:00')) }))

    dispatch(createAction('calendar.loadEvents', { list, cursor: json.cursor }))

    if (resolve) resolve({ ok: true })
  }
}

export function crudCalendarEvent ({ event, method }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL(`/admin/${method}-calendar-event`, config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify(event)
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve({ ok: false, error: json.error })
      }

      dispatch(loadCalendarEvents(resolve))
    }
  })

  return { action, promise }
}
