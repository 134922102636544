import React from 'react'
import classNames from 'classnames'

import { MenuBar } from '../components'
import { Hero, Container } from '.'

class Page extends React.Component {
  render () {
    return (
      <div className={classNames('page', this.props.classes)}>
        <MenuBar />
        <Hero classes='is-fullheight-with-navbar' innerClasses={this.props.topAligned ? 'page__top-aligned' : ''}>
          <Container>
            {this.props.children}
          </Container>
        </Hero>
      </div>
    )
  }
}

export default Page
