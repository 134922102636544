const initialState = {
  list: [],
  loaded: false,
  cursor: null,
  locations: {
    loaded: false,
    list: []
  },
  location: 'All Locations',
  timeShift: 0
}

const ordersReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'orders.load') return { ...state, list: action.list, cursor: action.cursor, loaded: true }
  if (type === 'orders.unload') return { ...state, list: [], cursor: null, loaded: false }

  if (type === 'orders.loadInfinity') {
    return {
      ...state,
      list: [...state.list, ...action.list].filter((v, i, a) => a.map(order => order.id).indexOf(v.id) === i),
      cursor: action.cursor,
      loaded: true
    }
  }

  if (type === 'orders.update') {
    const orderInList = state.list.filter(order => order.id === action.order.id).length

    if (orderInList) { // order in list, replace listed item
      return {
        ...state,
        list: state.list.map(order => {
          if (order.id !== action.order.id) return order
          else return action.order
        })
      }
    }

    // order not in list, add to the beginning
    return {
      ...state,
      list: [action.order, ...state.list]
    }
  }

  if (type === 'orders.loadLocations') {
    return {
      ...state,
      locations: {
        ...state.locations,
        loaded: true,
        list: action.locations
      }
    }
  }
  if (type === 'orders.unloadLocations') {
    return {
      ...state,
      locations: {
        ...state.locations,
        loaded: false,
        list: []
      }
    }
  }

  if (type === 'orders.timeShift') {
    return {
      ...state,
      timeShift: action.timeShift
    }
  }

  if (type === 'orders.setLocation') return { ...state, location: action.location }

  return state
}

export default ordersReducer
