import config from '../config'

import createAction from '.'

export function login () {
  return async (dispatch, getState) => {
    dispatch(createAction('user.loginInProgress'))

    try {
      const { username, password } = getState().user.loginForm

      const response = await window.fetch(new window.URL('/admin/login', config.apiUrl).href, {
        method: 'post',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({
          login: username,
          password
        })
      })
      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Validation failed') return dispatch(createAction('user.loginError', { error: 'Login failed: Invalid login', clear: true }))
        if (json.error === 'Invalid login') return dispatch(createAction('user.loginError', { error: 'Login failed: Invalid login', clear: true }))

        return dispatch(createAction('user.loginError', { error: `Unknown error: ${json.error}` }))
      }

      const token = json.token
      if (!token) return dispatch(createAction('user.loginError', { error: 'Login failed: Token not found' }))

      const group = json.group

      dispatch(createAction('user.login', { username, token, group }))
    } catch (e) {
      console.error('error while login:', e)
      dispatch(createAction('user.loginError', { error: 'Login failed: Network error' }))
    }
  }
}

export function testLogin (retries = 5) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    try {
      const response = await window.fetch(new window.URL('/admin/login-test', config.apiUrl).href, {
        method: 'get',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        }
      })
      const json = await response.json()

      if (!json.ok) {
        if (json.error === 'Login required') return dispatch(createAction('user.logout'))
        else throw Error()
      }
      dispatch(createAction('user.loginTested', { timestamp: new Date().getTime() }))
    } catch (e) {
      if (!(retries > 1)) dispatch(createAction('user.loginError', { error: 'Token verification failed: Network error' }))
      else dispatch(testLogin(retries - 1))
    }
  }
}
