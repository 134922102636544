import React, { useState } from 'react'
import classNames from 'classnames'

const defaultState = {
  active: false,
  title: '',
  message: '',
  responses: [],
  callback: null,
  options: {}
}

const defaultResponse = {
  text: 'OK',
  className: '',
  response: 'ok',
  isCancel: true
}

const useModal = () => {
  const [setup, setSetup] = useState(defaultState)
  const reset = () => setSetup(defaultState)

  const showModal = (title = '', message = '', responses = [defaultResponse], options) => new Promise((resolve, reject) => {
    setSetup({
      active: true,
      title,
      message,
      responses,
      callback: (response) => () => {
        reset()
        resolve(response)
      },
      options
    })
  })

  const cancelResponse = (setup.responses.filter(response => response.isCancel)[0] || { response: null }).response

  const modal = (
    <>
      {
        !setup.active ? undefined : (
          <div className='modal is-active'>
            <div className='modal-background' onClick={setup.callback(cancelResponse)} />
            <div className='modal-card'>
              <header className='modal-card-head'>
                <p className='modal-card-title'>{setup.title}</p>
                <button className='delete' aria-label='close' onClick={setup.callback(cancelResponse)} />
              </header>
              <section className='modal-card-body'>
                {setup.message}
              </section>
              <footer className='modal-card-foot' style={{ justifyContent: 'flex-end' }}>
                {
                  setup.responses.map((response, index) => {
                    if (response.url) {
                      return (
                        <a
                          className={classNames('button', response.className)}
                          href={response.url}
                          target='_blank'
                          rel='noopener noreferrer'
                          onClick={setup.callback(response.response)}
                          key={index}
                        >
                          {response.text}
                        </a>
                      )
                    }

                    return (
                      <button
                        className={classNames('button', response.className)}
                        onClick={setup.callback(response.response)}
                        key={index}
                      >
                        {response.text}
                      </button>
                    )
                  })
                }
              </footer>
            </div>
          </div>
        )
      }
    </>
  )

  return [modal, showModal]
}

export default useModal
