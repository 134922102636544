import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faFilter, faSyncAlt, faCaretRight } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import { Container } from '../containers'
import createAction from '../actions'
import { loadFilteredCalendarEvents } from '../actions/calendar'
import { Link } from 'react-router-dom'

const statusStyles = {
  NEW: 'is-danger',
  VIEWED: 'is-warning',
  'CONTACT CUSTOMER': 'is-warning',
  CONTRACT: 'is-warning',
  CONFIRMED: 'is-success',
  CLOSED: 'is-light',
  CANCELLED: 'is-light'
}
const statuses = Object.keys(statusStyles)

const removeFromArray = (array, ...fields) => array.filter(value => !fields.includes(value))

const FilteredEventsList = ({ onExit }) => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state.calendar.filters.statuses)
  const setFilters = (statuses) => dispatch(createAction('calendar.filterStatuses', { statuses }))

  const calendarLocation = useSelector(state => state.calendar.location)
  const setCalendarLocation = event => dispatch(createAction('calendar.setLocation', { location: event.target.value }))

  const eventsLoaded = useSelector(state => state.calendar.events.loaded)
  const events = useSelector(state => state.calendar.events.list).filter(event => {
    if (calendarLocation === 'All Locations') return true

    return event.config.location.name === calendarLocation
  })

  const reload = () => dispatch(loadFilteredCalendarEvents())

  useEffect(() => {
    dispatch(createAction('calendar.filterStatuses', { statuses: removeFromArray(statuses, 'CLOSED', 'CANCELLED') }))
    reload()
  }, [dispatch]) // eslint-disable-line

  return (
    <>
      <div className='level'>
        <div className='level-left'>
          <button className='button is-primary' onClick={onExit}>
            <FontAwesomeIcon icon={faCaretLeft} />
            &nbsp;
            Back to Calendar
          </button>
        </div>
        <div>
          <h1 className='title'>Filtered Events</h1>
        </div>
        <div className='level-right'>
          <div className='select'>
            <select value={calendarLocation} onChange={setCalendarLocation}>
              <option value='All Locations'>All Locations</option>
              <option value='Las Colinas'>Las Colinas</option>
              <option value='Arlington'>Arlington</option>
              <option value='Richardson'>Richardson</option>
            </select>
          </div>
        </div>
      </div>
      <div className='level'>
        {
          statuses.map((status, index) => {
            const isChecked = filters.includes(status)

            return (
              <div key={index}>
                <label className='checkbox'>
                  <input
                    type='checkbox' checked={isChecked} onChange={
                      isChecked
                        ? () => setFilters(removeFromArray(filters, status))
                        : () => setFilters([...filters, status])
                    }
                  />
                  &nbsp;
                  <span className={classNames('tag', statusStyles[status])}>{status}</span>
                </label>
              </div>
            )
          })
        }
        <button className='button is-primary' onClick={reload}>
          <FontAwesomeIcon icon={faFilter} />
          &nbsp;
          Filter
        </button>
      </div>
      <div>
        {
          !eventsLoaded ? (
            <Container classes='has-text-centered'>
              <FontAwesomeIcon icon={faSyncAlt} spin size='10x' />
            </Container>
          ) : (
            events.map(event => {
              const date = new Date(event.date + ' 0:00')

              return (
                <div className='filtered-events-list__event' key={event.id}>
                  <div className='filtered-events-list__event-square'>
                    <div className='filtered-events-list__event-day'>{date.toLocaleDateString('en-us', { day: 'numeric' })}</div>
                    <div className='filtered-events-list__event-month'>{date.toLocaleDateString('en-us', { month: 'short' })}</div>
                  </div>
                  <div className='filtered-events-list__event-status'>
                    <tag className={classNames('tag is-large filtered-events-list__event-status-tag', statusStyles[event.status])}>{event.status}</tag>
                  </div>
                  <div className='filtered-events-list__event-name'>
                    {event.name}
                    {' - '}
                    {event.config.guests} guests
                    {' - '}
                    {event.config.location.name}
                    {' - '}
                    {event.config.time}
                  </div>
                  <div className='filtered-events-list__go-button'>
                    <Link className='button is-primary' to={`/edit/event/${event.id}`}>
                      Go to Event
                      &nbsp;
                      <FontAwesomeIcon icon={faCaretRight} />
                    </Link>
                  </div>
                </div>
              )
            })
          )
        }
      </div>
    </>
  )
}

export default FilteredEventsList
