import config from '../config'
import createAction from '.'

export function loadCateringOrders (resolve, cursor) {
  return async (dispatch, getState) => {
    const token = getState().user.token
    const filters = getState().catering.filters

    if (!cursor) dispatch(createAction('catering.unload'))

    const filterFields = {}
    if (filters.email.enabled && filters.email.value) filterFields.email = filters.email.value
    if (filters.orderNumber.enabled) filterFields.orderNumber = filters.orderNumber.value
    if (filters.location.enabled && filters.location.value) filterFields.location = filters.location.value
    if (cursor) filterFields.cursor = cursor

    const response = await window.fetch(new window.URL('/admin/list-catering-orders', config.apiUrl).href, {
      method: 'post',
      body: JSON.stringify({
        // cursor,
        ...filterFields
      }),
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      // console.error('unknown error', json)
      return
    }

    // console.log(json)

    const list = json.cateringOrders

    // dispatch(createAction('catering.load', { list, cursor: json.cursor }))
    if (cursor) dispatch(createAction('catering.loadInfinity', { list, cursor: json.cursor }))
    else dispatch(createAction('catering.load', { list, cursor: json.cursor }))

    if (resolve) resolve({ ok: true })
  }
}
