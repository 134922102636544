import React, { useState } from 'react'
import useFetch from 'use-http'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faPrint, faClone } from '@fortawesome/free-solid-svg-icons'
import { Link, useHistory } from 'react-router-dom'

import { Page } from '../containers'
import { DateInput, OrdersReport, usePrinter } from '../components'

import config from '../config'
import xkcd1179 from '../util/xkcd1179'

const monthStart = new Date()
monthStart.setDate(1)
const monthEnd = new Date()
monthEnd.setDate(-1)
monthEnd.setMonth(monthEnd.getMonth() + 1)

const ReportsCateringPage = () => {
  const token = useSelector(state => state.user.token)
  const history = useHistory()

  const [startDate, setStartDate] = useState(xkcd1179(monthStart))
  const [endDate, setEndDate] = useState(xkcd1179(monthEnd))
  const [completeOnly, setCompleteOnly] = useState(false)

  if (token == null) history.push('/login')

  const [printer, doPrint] = usePrinter()

  const tomorrow = new Date(endDate + ' 0:00')
  tomorrow.setDate(tomorrow.getDate() + 1)

  const { loading, error, data } = useFetch(new URL('/admin/report-catering-orders', config.apiUrl).href, {
    method: 'post',
    headers: {
      // 'content-type': 'application/json',
      'x-user-token': token
    },
    body: {
      startDate: new Date(startDate + ' 0:00').toISOString(),
      endDate: tomorrow.toISOString(),
      completeOnly
    },
    cachePolicy: 'no-cache'
  }, [startDate, endDate, completeOnly])

  const printOrder = () => {
    if (!data) return
    doPrint(
      <div className='reports-page__print'>
        <div className='reports-page__print-header'>
          <img className='reports-page__print-logo' src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-500w.png' alt='' />
          <h1 className='title'>Catering Order Report</h1>
          <h2 className='subtitle'>
            {'Date range: from '}
            {
              startDate.toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
            }
            {' to '}
            {
              endDate.toLocaleString('en-us', { year: 'numeric', month: 'short', day: 'numeric' })
            }
          </h2>
        </div>
        <OrdersReport isCatering orders={data.orders} />
      </div>
    )
  }

  return (
    <Page>
      <div className='level'>
        <div className='level-left'>
          <h1 className='title'>Catering Report</h1>
        </div>
        <div className='level-right'>
          <Link to='/reports' className='button is-primary reports-page__button'>
            <FontAwesomeIcon icon={faClone} />
            &nbsp;
            Switch to Online Orders
          </Link>
          <button className='button is-primary reports-page__button' disabled={loading || error} onClick={printOrder}>
            <FontAwesomeIcon icon={faPrint} />
            &nbsp;
            Print Report
          </button>
        </div>
      </div>
      <div className='columns'>
        <div className='column is-two-thirds'>
          <div className='field'>
            <label className='label'>Date Range</label>
            <div className='control'>
              <div className='columns'>
                <div className='column'>
                  <div className='field'>
                    <label className='label'>From</label>
                    <div className='control'>
                      <DateInput defaultValue={startDate} onChange={(event) => setStartDate(xkcd1179(new Date(event.target.value)))} />
                    </div>
                  </div>
                </div>
                <div className='column'>
                  <div className='field'>
                    <label className='label'>To</label>
                    <div className='control'>
                      <DateInput defaultValue={endDate} onChange={(event) => setEndDate(xkcd1179(new Date(event.target.value)))} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Filters</label>
            <div className='control'>
              <div>
                <label className='checkbox'>
                  <input type='checkbox' checked={completeOnly} onChange={event => setCompleteOnly(event.target.checked)} />
                  &nbsp;
                  Complete Only
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        loading ? (
          <div className='reports-page__loader'>
            <FontAwesomeIcon icon={faSyncAlt} size='10x' spin />
          </div>
        ) : (
          error ? (
            <div className='reports-page__error'>
              <div className='message is-danger'>
                <div className='message-body'>
                  Server Error: {error.message}
                </div>
              </div>
            </div>
          ) : (
            <OrdersReport isCatering orders={data.orders} />
          )
        )
      }
      {printer}
    </Page>
  )
}

export default ReportsCateringPage
