import React from 'react'
import { connect } from 'react-redux'

import { Page, withUser } from '../containers'
import { LoadingScreenWithNavbar, Order, InfinityLoader } from '../components'

import { loadOrders, completeOrder } from '../actions/orders'
import createAction from '../actions'

class OrdersPage extends React.Component {
  constructor () {
    super()

    this.state = {
      selectedOrder: null
    }

    this.modalRef = React.createRef()
    this.selectOrder = (order) => () => {
      this.setState({ selectedOrder: order }, () => {
        if (this.modalRef.current) this.modalRef.current.open()
      })
    }
  }

  render () {
    const { list, loaded, currentLocation, setCurrentLocation } = this.props

    if (!loaded) {
      return <LoadingScreenWithNavbar />
    }

    const filteredList = list.filter(event => {
      if (currentLocation === 'All Locations') return true

      return event.config.location.name === currentLocation
    })

    return (
      <Page>
        <div className='level'>
          <div className='level-left'>
            <h1 className='title'>Orders</h1>
          </div>
          <div className='level-right'>
            <div className='select'>
              <select value={currentLocation} onChange={setCurrentLocation}>
                <option value='All Locations'>All Locations</option>
                <option value='Las Colinas'>Las Colinas</option>
                <option value='Arlington'>Arlington</option>
                <option value='Richardson'>Richardson</option>
              </select>
            </div>
          </div>
        </div>
        {
          filteredList.map(order => <Order key={order.id} order={order} />)
        }
        {
          this.props.cursor ? <InfinityLoader onTrigger={() => this.props.loadOrders(this.props.cursor)} /> : undefined
        }
      </Page>
    )
  }

  componentDidMount () {
    this.props.loadOrders()
  }
}

export default connect(
  state => ({
    list: state.orders.list,
    loaded: state.orders.loaded,
    cursor: state.orders.cursor,
    currentLocation: state.orders.location
  }),
  dispatch => ({
    loadOrders: (cursor) => dispatch(loadOrders(cursor)),
    completeOrder: (id) => dispatch(completeOrder(id)),
    setCurrentLocation: (event) => dispatch(createAction('orders.setLocation', { location: event.target.value }))
  })
)(withUser(OrdersPage))
