import React, { useState, useEffect, useRef } from 'react'

const InfinityLoader = ({ onTrigger }) => {
  const [isInView, setInView] = useState(false)
  const ref = useRef()

  useEffect(() => {
    const handler = () => {
      const element = ref.current
      if (!element) return

      const rect = element.getBoundingClientRect()
      if (!rect.width && !rect.height) return

      if (rect.top < window.innerHeight) {
        if (isInView) return
        setInView(true)

        console.log('infinity loader triggered')
        if (typeof onTrigger === 'function') onTrigger()
      } else if (isInView) setInView(false)
    }

    document.addEventListener('scroll', handler, true)
    return () => document.removeEventListener('scroll', handler, true)
  }, [isInView, onTrigger])

  return (
    <div ref={ref} />
  )
}

export default InfinityLoader
