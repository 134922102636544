import config from '../config'
import createAction from '.'

export function loadConfig (name) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    const response = await window.fetch(new window.URL('/admin/get-config', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        name
      })
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      if (json.error === `Config '${name}' is not set`) {
        dispatch(createAction('configs.set', {
          config: {
            name,
            value: null,
            meta: null
          }
        }))
      }

      console.error('unknown error', json)
      return
    }

    // console.log(json)

    dispatch(createAction('configs.set', {
      config: {
        name: json.name,
        value: json.value,
        meta: json.meta
      }
    }))
  }
}

export function saveConfig (newConfig) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    dispatch(createAction('configs.unload', { name: newConfig.name }))

    const response = await window.fetch(new window.URL('/admin/set-config', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        name: newConfig.name,
        value: newConfig.value,
        meta: newConfig.meta
      })
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      console.error('unknown error', json)
      return
    }

    // console.log(json)

    dispatch(createAction('configs.set', {
      config: {
        name: json.name,
        value: json.value,
        meta: json.meta
      }
    }))
  }
}
