import watch from 'redux-watch'

import createAction from './actions'
import config from './config'

const wsState = {
  socket: null
}

const connectWs = (token, store) => {
  if (!token) return

  if (wsState.socket) {
    const oldSocket = wsState.socket
    wsState.socket = null
    oldSocket.close()
  }

  const url = new window.URL('/admin/ordering/order-feed', config.apiUrl)
  url.searchParams.append('x-user-token', token)
  url.searchParams.append('includeEvents', true)
  url.searchParams.append('confirmedOnly', true)
  url.protocol = url.protocol === 'http:' ? 'ws' : 'wss'

  const socket = new window.WebSocket(url.href)
  wsState.socket = socket

  socket.onclose = () => {
    if (wsState.socket === socket) {
      console.log('[ws] reconnecting')
      connectWs(token, store)
    }
  }

  socket.onmessage = (message) => {
    const data = JSON.parse(message.data)
    console.log(`[ws] got update for order ${data.id}`)

    store.dispatch(createAction('orders.update', { order: data }))
  }

  socket.onopen = () => {
    console.log('[ws] connected to server')
  }
}

const updateTimeShift = async (store) => {
  try {
    const timeBefore = new Date().getTime()
    const response = await window.fetch(new window.URL('/admin/time', config.apiUrl))
    const timeAfter = new Date().getTime()

    const json = await response.json()

    const middleTime = (timeBefore + timeAfter) / 2
    const timeShift = json.timestamp - middleTime
    if (isNaN(timeShift)) throw new Error('[clock sync] cannot calculate time shift')

    console.log('[clock sync] time shift is', timeShift)
    store.dispatch({ type: 'orders.timeShift', timeShift })
  } catch (err) {
    console.warn('[clock sync] time shift update failed:', err)
  }

  setTimeout(updateTimeShift, 60000)
}

export default (store) => {
  const watchToken = watch(store.getState, 'user.token')

  store.subscribe(watchToken((newVal) => {
    connectWs(newVal, store)
  }))

  const state = store.getState()
  const token = state.user && state.user.token

  if (token) connectWs(token, store)

  updateTimeShift(store)
}
