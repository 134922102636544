import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
// import { useParams } from 'react-router-dom'
import { Link, Redirect, useParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretLeft, faCheckCircle, faPrint, faCreditCard, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import config from '../config'

import { Page, withUser } from '../containers'
import { LoadingScreenWithNavbar, Order, usePrinter, useModal } from '../components'

import { loadOrders, completeOrder, markOrderAsPrinted, loadLocations } from '../actions/orders'

const readableDate = date => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' })
const readableOrderStatus = (status) => {
  return {
    COMPLETE: (
      <>
        <span className='tag kitchen-page__tag is-dark'>
          <FontAwesomeIcon className='kitchen-page__tag-icon' icon={faCheckCircle} />
        Complete
        </span>
      </>
    ),
    CONFIRMED: (
      <>
        <span className='tag kitchen-page__tag is-dark'>Active</span>
        <span className='tag kitchen-page__tag is-dark'>
          <FontAwesomeIcon className='kitchen-page__tag-icon' icon={faCreditCard} />
        Prepaid
        </span>
      </>
    ),
    UNCONFIRMED: 'Unconfirmed',
    DEFERRED: (
      <>
        <span className='tag kitchen-page__tag is-dark'>Active</span>
        <span className='tag kitchen-page__tag is-dark'>
          <FontAwesomeIcon className='kitchen-page__tag-icon' icon={faHandHoldingUsd} />
        Payment at Pickup
        </span>
      </>
    )
  }[status] || 'Unknown'
}

const KitchenPage = ({ list, locations, loaded, loadOrders, loadLocations, completeOrder, markAsPrinted }) => {
  const params = useParams()
  const id = isNaN(params.id) ? null : Number(params.id)

  const [printer, print] = usePrinter()
  const [modal, showModal] = useModal()
  // const [blinking, setBlinking] = useState(false)

  useEffect(() => {
    loadOrders()
    loadLocations()
  }, []) // eslint-disable-line

  const [nowMinute, setNowMinute] = useState()
  useEffect(() => {
    const tick = () => setNowMinute(Math.floor(new Date().getTime() / 60000))
    const interval = setInterval(tick, 60000)
    tick()

    return () => clearInterval(interval)
  }, [])
  const timeShift = useSelector(state => state.orders.timeShift)

  if (!loaded) {
    return <LoadingScreenWithNavbar />
  }

  if (!id) {
    return (
      <Page>
        <h1 className='title'>Select a Kitchen:</h1>
        {
          locations.map(location => (
            <Link to={`/kitchen/${location.id}`} key={location.id} className='button kitchen-page__button'>
              {location.name}
            </Link>
          ))
        }
        {/* <table className='table is-hoverable is-striped'>
          <tbody>
            {
              locations.map(location => (
                <tr onClick={() => history.push(`/kitchen/${location.id}`)} key={location.id}>
                  <td>
                    {location.name}
                  </td>
                  <td>
                    <button className='button'>Go</button>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table> */}
      </Page>
    )
  }

  const currentLocation = locations.filter(location => location.id === id)[0]
  if (!currentLocation) {
    return <Redirect to='/kitchen' />
  }

  const filteredOrders = list
    .filter(order => order.config.location.id === currentLocation.id)
    .filter(order => ['CONFIRMED', 'DEFERRED', 'COMPLETE'].includes(order.status))
    .map(order => {
      const orderMinute = Math.floor((new Date(order.confirmedAt).getTime() - timeShift) / 60000)
      const minutesAgo = nowMinute - orderMinute

      const isPaid = order.status === 'CONFIRMED'
      const isUnpaid = order.status === 'DEFERRED'
      const isActive = isPaid || isUnpaid

      return { ...order, minutesAgo, isActive, isPaid, isUnpaid }
    })
    .filter(order => order.isActive || order.minutesAgo <= config.orderTimeout)

  const hasUnprinted = filteredOrders.filter(order => {
    if (order.status === 'COMPLETE') return false
    return !order.events.filter(event => event.eventType === 'print').length
  }).length

  return (
    <Page classes={classNames({
      'kitchen-page--blinking': hasUnprinted
    })}
    >
      <div className='level'>
        <div className='level-left'>
          <Link to='/kitchen' className='button'>
            <FontAwesomeIcon icon={faCaretLeft} />
            &nbsp;
            Back
          </Link>
        </div>
        <div>
          <h1 className='title'>{currentLocation.name}</h1>
        </div>
        <div className='level-right' />
      </div>
      {/* order list */}
      {filteredOrders.length ? undefined : <p className='kitchen-page__placeholder'>No orders received in the last {config.orderTimeout} minutes</p>}
      {
        filteredOrders.map(order => {
          const { minutesAgo, isActive, isPaid, isUnpaid } = order
          const minutesPostfix = minutesAgo === 1 ? 'min' : 'mins'

          const completed = readableDate(order.completedAt)

          const printInvoice = async () => {
            await print(<Order order={order} userFacing print />)
            markAsPrinted(order.id)
          }
          // const printWorksheet = async () => {
          //   print(<Order order={order} kitchenSheet print />)
          //   markAsPrinted(order.id)
          // }

          const orderPickupTime = (!order.config.pickupTime || order.config.pickupTime === 'ASAP') ? (
            new Date(new Date(order.confirmedAt).getTime() + 1200000).toLocaleString('en-us', { timeZone: 'America/Chicago', hour: 'numeric', minute: 'numeric' })
          ) : order.config.pickupTime

          const complete = async () => {
            const result = await showModal(
              'Complete Order',
              `Are you sure you want to complete order #${order.id}? (${order.config.name})`,
              [{
                text: 'Yes',
                response: 'yes',
                className: 'is-success kitchen-page__button'
              }, {
                text: 'No',
                response: 'no',
                className: 'is-danger kitchen-page__button',
                isCancel: true
              }]
            )

            if (result !== 'yes') return
            completeOrder(order.id)
          }

          const printCount = order.events.filter(event => event.eventType === 'print').length

          return (
            <div
              className={classNames('message', {
                'is-success': isPaid && printCount,
                'is-danger': isUnpaid || !printCount
              })} key={order.id}
            >
              <div className='message-body'>
                <div className='level'>
                  <div className='level-left'>
                    <div>
                      <h1 className='title'>{order.id} - {order.config.name} {isActive ? `- ${minutesAgo} ${minutesPostfix}` : ''}</h1>
                      <h2 className='subtitle'>
                        {readableOrderStatus(order.status)}
                        {
                          printCount ? (
                            <span className='tag kitchen-page__tag is-dark'>Printed {printCount} times</span>
                          ) : (
                            <span className='tag kitchen-page__tag is-danger'><strong>NOT YET PRINTED</strong></span>
                          )
                        }
                      </h2>
                    </div>
                  </div>
                  <div className='level-right'>
                    {
                      !isUnpaid ? undefined : (
                        <h1 className='title'>Payment due: <strong>${order.config.price}</strong></h1>
                      )
                    }
                  </div>
                </div>
                <div className='level'>
                  <div className='level-left'>
                    <div>
                      <p>
                        Ordered at {readableDate(order.confirmedAt)}
                      </p>
                      <p>
                        Pickup time: {orderPickupTime}
                      </p>
                      <p className='mb-2'>
                        <strong>Phone number: {order.config.phone}</strong>
                      </p>
                      <table className='table is-striped'>
                        <thead>
                          <tr>
                            <th>Quantity</th>
                            <th>Item</th>
                            <th>Platter items</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.config.items.map((item, index) => (
                            <tr key={index}>
                              <td><strong>{item.quantity}</strong></td>
                              <td>{item.name}</td>
                              <td style={{ whiteSpace: 'pre-wrap' }}>
                                {item.items ? (
                                  item.items
                                    .map(subitem => subitem.selections.map(selection => selection.name))
                                    .flat()
                                    .reduce((acc, item) => {
                                      const existing = acc.filter(i => i.name === item)[0]

                                      if (existing) existing.quantity++
                                      else acc.push({ name: item, quantity: 1 })

                                      return acc
                                    }, [])
                                    .map(({ name, quantity }) => quantity === 1 ? name : `${name} x${quantity}`)
                                    .join('\n')
                                ) : ''}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className='order__special-request'>
                        <h2 className='subtitle order__special-request-title'>Special Request</h2>
                        <div className='order__special-request-text'>
                          {order.config.specialRequest || 'None'}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='level-right kitchen-page__controls'>
                    <div>
                      {
                        isActive
                          ? (
                            <>
                              <button className='button is-success kitchen-page__button' onClick={complete} disabled={!printCount}>
                                <FontAwesomeIcon icon={faCheckCircle} />
                                &nbsp;
                                Complete
                              </button>
                              {/* <button className='button is-info kitchen-page__button' onClick={() => printWorksheet()}>
                                <div>
                                  <FontAwesomeIcon icon={faPrint} />
                                  &nbsp;
                                  Print
                                  <br />
                                  <small className='kitchen-page__small'>Worksheet</small>
                                </div>
                              </button>
                              &nbsp; */}
                              <button className='button is-success kitchen-page__button' onClick={() => printInvoice()}>
                                <div>
                                  <FontAwesomeIcon icon={faPrint} />
                                  &nbsp;
                                  Print
                                  <br />
                                  <small className='kitchen-page__small'>Invoice</small>
                                </div>
                              </button>
                            </>
                          ) : (
                            <>
                              <h2 className='subtitle'>Order completed at {completed}</h2>
                              <div className='kitchen-page__placeholder'>
                                <button className='button kitchen-page__button' onClick={() => printInvoice()}>
                                  <FontAwesomeIcon icon={faPrint} />
                                  &nbsp;
                                  Print invoice
                                </button>
                              </div>
                            </>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })
      }
      {printer}
      {modal}
    </Page>
  )
}

export default connect(
  state => ({
    list: state.orders.list,
    locations: state.orders.locations.list,
    loaded: state.orders.loaded && state.orders.locations.loaded
  }),
  dispatch => ({
    loadOrders: () => dispatch(loadOrders()),
    loadLocations: () => dispatch(loadLocations()),
    completeOrder: (id) => dispatch(completeOrder(id)),
    markAsPrinted: (id) => dispatch(markOrderAsPrinted(id))
  })
)(withUser(KitchenPage))
