import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

class NumberInput extends React.Component {
  render () {
    return (
      <div className={classNames('number-input field has-addons', {
        'number-input--small': this.props.small
      }, this.props.classes)}
      >
        {
          !this.props.buttonsHidden ? (
            <div className='control'>
              <button
                className={classNames('button', {
                  'is-small': this.props.small
                })} onClick={this.props.onDecrease} disabled={this.props.minusDisabled || this.props.disabled}
              >
                <FontAwesomeIcon icon={faMinus} className='icon' />
              </button>
            </div>
          ) : undefined
        }
        <div className='control number-input__middle-control'>
          <input
            className={classNames('input number-input__input', {
              'is-small': this.props.small
            })} value={
              this.props.rawString || String(!isNaN(this.props.value) ? this.props.value : 0)
            } onChange={(event) => {
              if (!isNaN(event.target.value) && this.props.onChange) this.props.onChange(event)
            }} disabled={this.props.disabled}
          />
        </div>
        {
          !this.props.buttonsHidden ? (
            <div className='control'>
              <button
                className={classNames('button', {
                  'is-small': this.props.small
                })} onClick={this.props.onIncrease} disabled={this.props.plusDisabled || this.props.disabled}
              >
                <FontAwesomeIcon icon={faPlus} className='icon' />
              </button>
            </div>
          ) : undefined
        }
      </div>
    )
  }
}

export default NumberInput
