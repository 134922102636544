import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faCreditCard, faCheckCircle, faChevronUp, faBan, faPrint, faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import { markOrder } from '../actions/orders'
import { usePrinter } from '../components'

const readableDate = date => new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit' })
const readableOrderStatus = (status) => {
  return {
    COMPLETE: 'Complete',
    CONFIRMED: 'Paid',
    DEFERRED: 'Payment at Pickup',
    UNCONFIRMED: 'Unconfirmed',
    CANCELED: 'Canceled',
    UNFINISHED: 'Unfinished'
  }[status] || 'Unknown'
}
const orderIcon = (status) => {
  return {
    COMPLETE: faCheckCircle,
    CONFIRMED: faCreditCard,
    DEFERRED: faHandHoldingUsd,
    CANCELED: faBan
  }[status] || undefined
}
const readableEventType = (type) => {
  return {
    mark: 'Marked',
    complete: 'Completed',
    payment: 'Payment',
    defer: 'Confirmed',
    cancel: 'Canceled (unfinished)',
    print: 'Printed in kitchen'
  }[type] || 'Unknown'
}

const Order = ({ order, userFacing, kitchenSheet, print }) => {
  const dispatch = useDispatch()
  const [printer, doPrint] = usePrinter()
  const [expanded, setExpanded] = useState(false)

  const expand = () => setExpanded(true)
  const collapse = () => setExpanded(false)
  const toggle = expanded ? collapse : expand

  const [userFacingPrintInProgress, setUserFacingPrintProgress] = useState(false)
  // const [kitchenSheetPrintInProgress, setKitchenSheetPrintProgress] = useState(false)

  const items = order.config.items.length
  const itemsWord = items === 1 ? 'Item' : 'Items'
  const orderedAt = readableDate(order.createdAt)
  const status = readableOrderStatus(order.status)

  const markAs = (status) => () => dispatch(markOrder(order.id, status))

  const printCustomerInvoice = () => {
    setUserFacingPrintProgress(true)
    doPrint(<Order order={order} userFacing print />).then(result => console.log('yes'), error => console.log('no', error))
  }
  // const printKitchenWorksheet = () => {
  //   setKitchenSheetPrintProgress(true)
  //   doPrint(<Order order={order} kitchenSheet print />)
  // }

  window.addEventListener('afterprint', (event) => {
    setUserFacingPrintProgress(false)
    // setKitchenSheetPrintProgress(false)
  })

  const orderPickupTime = (!order.config.pickupTime || order.config.pickupTime === 'ASAP') ? (
    new Date(new Date(order.confirmedAt).getTime() + 1200000).toLocaleString('en-us', { timeZone: 'America/Chicago', hour: 'numeric', minute: 'numeric' })
  ) : order.config.pickupTime

  const orderContent = (
    <>
      <div className='columns'>
        <div className='column'>
          <table className={classNames('table is-striped is-fullwidth is-hoverable', { 'is-narrow': print })}>
            <tbody>
              <tr>
                <td>Order number:</td>
                <td><strong>#{order.id}</strong></td>
              </tr>
              <tr>
                <td>Items:</td>
                <td>{items}</td>
              </tr>
              <tr>
                <td>Ordered at:</td>
                <td>{orderedAt}</td>
              </tr>
              <tr>
                <td>Pickup time:</td>
                <td>{orderPickupTime}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='column'>
          <table className={classNames('table is-striped is-fullwidth is-hoverable', { 'is-narrow': print })}>
            <tbody>
              <tr>
                <td>Customer:</td>
                <td><strong>{order.config.name}</strong></td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>{order.config.phone}</td>
              </tr>
              <tr>
                <td>Location:</td>
                <td>{order.config.location.name}</td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className='column'>
          <table className={classNames('table is-striped is-fullwidth is-hoverable', { 'is-narrow': print })}>
            <tbody>
              <tr>
                <td>Subtotal:</td>
                <td>${(order.config.subtotal || 0).toFixed(2)}</td>
              </tr>
              <tr>
                <td>Total:</td>
                <td><strong>${(order.config.price || 0).toFixed(2)}</strong></td>
              </tr>
              <tr>
                <td>Status:</td>
                <td>
                  {
                    print ? (
                      status
                    ) : (
                      <span className={classNames('tag', {
                        'is-light': ['UNCONFIRMED', 'COMPLETE', 'CANCELED', 'UNFINISHED'].includes(order.status),
                        'is-danger': order.status === 'DEFERRED',
                        'is-success': order.status === 'CONFIRMED',
                        'is-info': order.status === 'COMPLETE'
                      })}
                      >
                        {status}
                      </span>
                    )
                  }
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {
        kitchenSheet ? undefined : (
          <>
            <h2 className='subtitle'>Ordered items</h2>
            <div className='table-container'>
              <table className='table is-striped is-fullwidth is-hoverable'>
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Name</th>
                    <th>Contents (if platter)</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    order.config.items.map((item, index) => (
                      <tr key={index}>
                        <td>{item.quantity}</td>
                        <td>{item.name}</td>
                        <td>
                          {
                            item.items ? (
                              <table className='table is-striped is-fullwidth is-hoverable is-narrow'>
                                <thead>
                                  <tr>
                                    <th>Qty</th>
                                    <th>Name</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    item.items
                                      .map(subitem => subitem.selections.map(selection => selection.name))
                                      .reduce((acc, val) => acc.concat(val), [])
                                      .reduce((acc, item) => {
                                        const existing = acc.filter(i => i.name === item)[0]

                                        if (existing) existing.quantity++
                                        else acc.push({ name: item, quantity: 1 })

                                        return acc
                                      }, [])
                                      .map(({ name, quantity }, index) => (
                                        <tr key={index}>
                                          <td>{quantity}</td>
                                          <td>{name}</td>
                                        </tr>
                                      ))
                                  }
                                </tbody>
                              </table>
                            ) : ''
                          }
                        </td>
                        <td>${item.price.toFixed(2)}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </>
        )
      }

      <div className='level order__event-level'>
        <div className='level-left order__event-list'>
          {
            print ? undefined : (
              <div className='order__special-request'>
                <h2 className='subtitle order__special-request-title'>Special Request</h2>
                <div className='order__special-request-text'>
                  {order.config.specialRequest || 'None'}
                </div>
              </div>
            )
          }
          {
            userFacing ? undefined : (
              <div className='order__events'>
                <h2 className='subtitle'>Order Events</h2>
                <table className='table is-narrow is-striped is-hoverable'>
                  <thead>
                    <tr>
                      <th>Event ID</th>
                      <th>Event Type</th>
                      <th>Issued by</th>
                      <th>Event Data</th>
                      <th>Time of Event</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      order.events.map(event => {
                        let eventData = ''

                        if (event.eventType === 'mark') {
                          eventData =
                            <>
                              Marked as {print
                                ? readableOrderStatus(event.config.status)
                                : (
                                  <span className={classNames('tag', {
                                    'is-light': ['UNCONFIRMED', 'COMPLETE', 'CANCELED', 'UNFINISHED'].includes(event.config.status),
                                    'is-danger': event.config.status === 'DEFERRED',
                                    'is-success': event.config.status === 'CONFIRMED'
                                  })}
                                  >
                                    {readableOrderStatus(event.config.status)}
                                  </span>
                                )}
                            </>
                        }

                        if (event.eventType === 'complete') eventData = 'Completed in kitchen'

                        if (event.eventType === 'payment') {
                          if (event.config.paymentType === 'dummy') eventData = 'Dummy payment'
                          else if (event.config.paymentType === 'credit-card') eventData = `Credit Card Payment (Transaction #${event.config.transactionId})`
                          else {
                            console.warn('unknown payment event found', event)
                          }
                        }

                        if (event.eventType === 'defer') eventData = 'Marked for Payment at Pickup'

                        return (
                          <tr key={event.id}>
                            <td>{event.id}</td>
                            <td>{readableEventType(event.eventType)}</td>
                            <td>{event.config.markedBy || ''}</td>
                            <td>{eventData}</td>
                            <td>{readableDate(event.createdAt)}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
        <div className='level-right order__total-wrapper'>
          {
            kitchenSheet ? undefined : (
              <div className='order__total'>
                <table className='table is-striped is-hoverable'>
                  <tbody>
                    <tr>
                      <td>Subtotal:</td>
                      <td>${(order.config.subtotal || 0).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Tip:</td>
                      <td>${(order.config.tip || 0).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>Tax:</td>
                      <td>${(order.config.tax || 0).toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td><strong>${(order.config.price || 0).toFixed(2)}</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )
          }
        </div>
      </div>
    </>
  )

  const deduplicateItems = (acc, item) => {
    if (item.platter) {
      for (let i = 0; i < item.quantity; i++) {
        acc[Symbol('platter')] = {
          name: item.name,
          quantity: 1,
          platter: item.platter
        }
      }
      return acc
    }

    if (acc[item.id]) {
      acc[item.id].quantity += (item.quantity || 1)
      return acc
    }

    acc[item.id] = {
      name: item.name,
      quantity: item.quantity || 1
    }
    return acc
  }
  const sortItems = items => Reflect.ownKeys(items).map(key => items[key]).sort((a, b) => a.name.localeCompare(b.name))
  const sortedItemsToMake = order.config.items
    .map(item => {
      if (item.type === 'platter') {
        const itemsToMake = item.items.map(category => {
          return category.selections
        }).flat().reduce(deduplicateItems, {})

        return {
          id: item.id,
          name: item.name,
          quantity: item.quantity,
          platter: sortItems(itemsToMake)
        }
      }

      return [item] // simple items
    })
    .flat()
    .reduce(deduplicateItems, {})
  const allItemsToMake = sortItems(sortedItemsToMake)

  // console.log(sortedItemsToMake, allItemsToMake)

  if (print) {
    const address = order.config.location.address

    return (
      <div className='order__print'>
        <div className='columns'>
          <div className='column is-two-fifths'>
            <div className='order__print-header'>
              {/* <img className='order__print-logo' src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-500w.png' alt='' /> */}
            </div>
            {
              (address && !kitchenSheet) ? (
                <div className='order__print-address'>
                  <div className='order__print-address-line'>Andalous Mediterranean Grill</div>
                  <div className='order__print-address-line'>{address.line1}</div>
                  {
                    address.line2 ? (
                      <div className='order__print-address-line'>{address.line2}</div>
                    ) : undefined
                  }
                  <div className='order__print-address-line'>{address.cityLine}</div>
                  <div className='order__print-address-line'>ph: {order.config.location.phoneNumber}</div>
                  {
                    address.fax ? (
                      <div className='order__print-address-line'>fx: {address.fax}</div>
                    ) : undefined
                  }
                  <div className='order__print-address-line order__print-address-thank-you-line'>
                    Thank you for your order
                  </div>
                </div>
              ) : undefined
            }
          </div>
          <div className='column is-three-fifths order__print-oversized-column'>
            <div>
              <div className='order__print-oversized-box'>
                <div className='order__print-slightly-less-oversized'>Order #{order.id}</div>
                <div className='order__print-slightly-less-oversized'>{order.config.name}</div>
                <div className='order__print-slightly-less-oversized'>{order.config.location.name}</div>
                <div className='order__print-slightly-less-oversized'>
                  {orderPickupTime}
                </div>
              </div>
              <div className='order__special-request order__special-request--print'>
                <h2 className='subtitle order__special-request-title'>Special Request</h2>
                <div className='order__special-request-text'>
                  {order.config.specialRequest || 'None'}
                </div>
              </div>
            </div>
          </div>
        </div>
        {kitchenSheet ? undefined : orderContent}
        {
          kitchenSheet ? (
            <div className='order__kitchen-worksheet'>
              <h2 className='subtitle'>Worksheet (Order #{order.id})</h2>
              <table className='table is-striped is-fullwidth is-hoverable'>
                <thead>
                  <tr>
                    <th>Qty</th>
                    <th>Name</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    allItemsToMake.map((item, index) => (
                      <tr key={index}>
                        <td>{item.quantity}</td>
                        <td>
                          {item.name}
                          {
                            item.platter ? (
                              <table className='table is-striped is-hoverable is-fullwidth'>
                                <thead>
                                  <tr>
                                    <th>Qty</th>
                                    <th>Name</th>
                                    <th />
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    item.platter.map((subItem, subIndex) => (
                                      <tr key={subIndex}>
                                        <td>{subItem.quantity}</td>
                                        <td>{subItem.name}</td>
                                        <td className='order__square-column'>
                                          <div className='order__square' />
                                          <div className='order__square' />
                                        </td>
                                      </tr>
                                    ))
                                  }
                                </tbody>
                              </table>
                            ) : undefined
                          }
                        </td>
                        <td className='order__square-column'>
                          <div className='order__square' />
                          <div className='order__square' />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          ) : undefined
        }
        {kitchenSheet ? orderContent : undefined}
      </div>
    )
  }

  const printCount = order.events.filter(event => event.eventType === 'print').length

  return (
    <article className={classNames('message order', {
      'is-light': ['UNCONFIRMED', 'COMPLETE', 'CANCELED', 'UNFINISHED'].includes(order.status),
      'is-danger': order.status === 'DEFERRED' || (order.status === 'CONFIRMED' && !printCount),
      'is-success': order.status === 'CONFIRMED' && printCount,
      'order--expanded': expanded,
      'order--collapsed': !expanded
    })}
    >
      <div className='message-header order__header' onClick={toggle}>
        <p>
          <span className='tag is-dark'>
            <FontAwesomeIcon icon={orderIcon(order.status)} className='order__header-icon' />
            {status}
          </span>
        </p>
        <p>
          #{order.id} - {order.config.name} - {items} {itemsWord} - ${(order.config.price || 0).toFixed(2)}
        </p>
        <div className='button is-small is-dark'>
          <span className='icon'>
            <FontAwesomeIcon icon={expanded ? faChevronUp : faChevronDown} />
          </span>
        </div>
      </div>
      <div className='message-body order__body'>
        {orderContent}

        <div className='is-divider' />

        <div className='level'>
          <div className='level-left'>
            <div className='level'>
              <div className='level-left'>
                <div className='order__mark-as'>
                  Mark as
                </div>
              </div>
              <div className='level-right order__mark-buttons'>
                {/* <div className='order__mark-button-row'>
                  <div className='button is-success is-small' onClick={markAs('CONFIRMED')}>
                    <FontAwesomeIcon icon={faCreditCard} />
                    &nbsp;
                    Paid
                  </div>
                  &nbsp;
                  <div className='button is-danger is-small' onClick={markAs('DEFERRED')}>
                    <FontAwesomeIcon icon={faHandHoldingUsd} />
                    &nbsp;
                    UnpaidUnpaid
                  </div>
                  &nbsp;
                  <div className='button is-light is-small' onClick={markAs('UNCONFIRMED')}>
                    <FontAwesomeIcon icon={faShoppingCart} />
                    &nbsp;
                    Unconfirmed
                  </div>
                </div> */}
                <div className='order__mark-button-row'>
                  <div className='button is-success is-small' onClick={markAs('CONFIRMED')}>
                    <FontAwesomeIcon icon={faCreditCard} />
                    &nbsp;
                    Paid
                  </div>
                  &nbsp;
                  <div className='button is-light is-small' onClick={markAs('COMPLETE')}>
                    <FontAwesomeIcon icon={faCheckCircle} />
                    &nbsp;
                    Complete
                  </div>
                  &nbsp;
                  <div className='button is-light is-small' onClick={markAs('CANCELED')}>
                    <FontAwesomeIcon icon={faBan} />
                    &nbsp;
                    Canceled
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* button loading indicators */}
          <div>
            {/* <div className={`button is-info${kitchenSheetPrintInProgress ? ' is-loading' : ''}`} onClick={printKitchenWorksheet}>
              <FontAwesomeIcon icon={faPrint} />
              &nbsp;
              Print Kitchen Worksheet
            </div>
            &nbsp; */}
            <div className={`button is-success${userFacingPrintInProgress ? ' is-loading' : ''}`} onClick={printCustomerInvoice}>
              <FontAwesomeIcon icon={faPrint} />
              &nbsp;
              Print Customer Invoice
            </div>
          </div>
          <div className='level-right'>
            <div className='button is-dark' onClick={collapse}>
              <FontAwesomeIcon icon={faChevronUp} />
              &nbsp;
              Collapse
            </div>
          </div>
        </div>
        {printer}
      </div>
    </article>
  )
}

export default Order
