const initialState = {
  categories: [],
  foods: [],
  items: [],
  loaded: false
}

const menuReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'menu.load') {
    return {
      ...state,
      categories: action.categories,
      foods: action.foods,
      items: action.items,
      loaded: true
    }
  }
  if (type === 'menu.unload') {
    return {
      ...state,
      categories: [],
      foods: [],
      items: [],
      loaded: false
    }
  }

  return state
}

export default menuReducer
