import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/free-solid-svg-icons'

const FileInput = ({ onChange }) => {
  const [file, setFile] = useState(null)

  return (
    <div className='file has-name'>
      <label className='file-label'>
        <input
          className='file-input' type='file' accept='image/png, image/jpg, image/jpeg' onChange={(event) => {
            setFile(event.target.files[0] || null)
            if (typeof onChange === 'function') onChange(event.target.files[0], event)
          }}
        />
        <span className='file-cta'>
          <span className='file-icon'>
            <FontAwesomeIcon icon={faUpload} />
          </span>
          <span className='file-label'>
            Choose a file
          </span>
        </span>
        <span className='file-name'>
          {file ? file.name : 'No file selected'}
        </span>
      </label>
    </div>
  )
}

export default FileInput
