const askElectron = window.askElectron
const available = Boolean(askElectron)

const throwOnUnavailable = () => {
  if (!available) throw new TypeError('Printer interface not connected (is this an electron app?)')
}

const listPrinters = async () => {
  throwOnUnavailable()
  return askElectron('printer-list')
}

const selectPrinter = async (name) => {
  throwOnUnavailable()
  return askElectron('printer-select', name)
}

const print = async (html) => {
  throwOnUnavailable()
  return askElectron('printer-print', html)
}

window.listPrinters = listPrinters

export {
  available,
  listPrinters,
  selectPrinter,
  print
}
