import React from 'react'
import classNames from 'classnames'

class Hero extends React.Component {
  render () {
    return (
      <div className={classNames('hero', this.props.classes)}>
        {
          this.props.head
            ? (
              <div className={classNames('hero-head', this.props.headClasses)}>
                {this.props.head}
              </div>
            )
            : undefined
        }
        <div className={classNames('hero-body', this.props.innerClasses)}>
          {this.props.children}
        </div>
        {
          this.props.foot
            ? (
              <div className={classNames('hero-foot', this.props.footClasses)}>
                {this.props.foot}
              </div>
            )
            : undefined
        }
      </div>
    )
  }
}

export default Hero
