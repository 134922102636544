import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as yup from 'yup'

import { Page, withUser } from '../containers'
import { LoadingScreenWithNavbar, CateringOrder, NumberInputManaged, InfinityLoader } from '../components'

import { loadCateringOrders } from '../actions/catering'
import { loadLocations } from '../actions/orders'
import createAction from '../actions'

const CateringPage = () => {
  const state = useSelector(state => state.catering)
  const cursor = useSelector(state => state.catering.cursor)
  const dispatch = useDispatch()

  const email = state.filters.email.value
  const orderNumber = state.filters.orderNumber.value
  const location = state.filters.location.value
  const filterByEmail = state.filters.email.enabled
  const filterByOrderNumber = state.filters.orderNumber.enabled
  const filterByLocation = state.filters.location.enabled

  const setEmail = (value) => dispatch(createAction('catering.set-filter', { filter: 'email', value }))
  const setOrderNumber = (value) => dispatch(createAction('catering.set-filter', { filter: 'orderNumber', value }))
  const setLocation = (value) => dispatch(createAction('catering.set-filter', { filter: 'location', value }))
  const setFilterByEmail = (enabled) => dispatch(createAction('catering.toggle-filter', { filter: 'email', enabled }))
  const setFilterByOrderNumber = (enabled) => dispatch(createAction('catering.toggle-filter', { filter: 'orderNumber', enabled }))
  const setFilterByLocation = (enabled) => dispatch(createAction('catering.toggle-filter', { filter: 'location', enabled }))

  useEffect(() => {
    dispatch(loadCateringOrders())
  }, [dispatch])

  const locationsLoaded = useSelector(state => state.orders.locations.list)
  if (!locationsLoaded) dispatch(loadLocations())

  if (!state.loaded) return <LoadingScreenWithNavbar />

  const isValidEmail = yup.string().email().min(1).isValidSync(email)
  const canSubmit = (filterByEmail ? (email && isValidEmail) : true) && (filterByOrderNumber ? orderNumber : true)
  const doSubmit = () => dispatch(loadCateringOrders())

  return (
    <Page>
      <h1 className='title'>Catering Orders</h1>
      <div className='level catering-page__filter'>
        <div className='field catering-page__filter-field'>
          <label className='label checkbox'>
            <input type='checkbox' checked={filterByEmail} onChange={event => setFilterByEmail(event.target.checked)} />
            &nbsp;
            Filter by Email:
          </label>
          <div className='control'>
            <input className='input' disabled={!filterByEmail} value={email} onChange={event => setEmail(event.target.value)} />
          </div>
          <p className='help is-danger'>
            {
              (email && filterByEmail && !isValidEmail) ? (
                'invalid email'
              ) : '\xa0'
            }
          </p>
        </div>
        <div className='field catering-page__filter-field'>
          <label className='label checkbox'>
            <input type='checkbox' checked={filterByOrderNumber} onChange={event => setFilterByOrderNumber(event.target.checked)} />
            &nbsp;
            Filter by Order Number:
          </label>
          <div className='control'>
            <NumberInputManaged buttonsHidden disabled={!filterByOrderNumber} value={orderNumber} onChange={event => setOrderNumber(event.target.value)} />
          </div>
          <p className='help is-danger'>&nbsp;</p>
        </div>
        <div className='field catering-page__filter-field'>
          <label className='label checkbox'>
            <input type='checkbox' checked={filterByLocation} onChange={event => setFilterByLocation(event.target.checked)} />
            &nbsp;
            Filter by Location:
          </label>
          <div className='control'>
            {/* <input className='input' disabled={!filterByLocation} value={location} onChange={event => setLocation(event.target.value)} /> */}
            <div className='select'>
              <select disabled={!filterByLocation} value={location} onChange={event => setLocation(event.target.value)}>
                <option value='' disabled hidden>Select Location</option>
                <option value='Las Colinas'>Las Colinas</option>
                <option value='Arlington'>Arlington</option>
                <option value='Richardson'>Richardson</option>
              </select>
            </div>
          </div>
          <p className='help is-danger'>&nbsp;</p>
        </div>
        <div className='field catering-page__filter-field'>
          <label className='label checkbox'>&nbsp;</label>
          <div className='control'>
            <button className='button is-primary' disabled={!canSubmit} onClick={doSubmit}>Filter</button>
          </div>
          <p className='help is-danger'>&nbsp;</p>
        </div>
      </div>
      {
        state.list.map(order => <CateringOrder order={order} key={order.id} />)
      }
      {
        cursor ? <InfinityLoader onTrigger={() => dispatch(loadCateringOrders(null, cursor))} /> : undefined
      }
    </Page>
  )
}

export default withUser(CateringPage)
