import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Redirect, useParams, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import unwrap from 'async-unwrap'

import { Page, withUser } from '../../containers'
import { FileInput, FoodItemRow, LoadingScreenWithNavbar, PlatterItemRow, useModal, NumberInputManaged } from '../../components'
import uploadImage from '../../util/upload-image'

import createAction from '../../actions'
import { createFood, updateFood, deleteFood, createPlatter, updatePlatter, createItem, updateItem, deleteItem } from '../../actions/menu'

const EditFoodPage = ({ loaded, foods, items, categories, token, dispatch }) => {
  const params = useParams()
  const id = isNaN(params.id) ? params.id : Number(params.id)

  const history = useHistory()

  const [modal, showModal] = useModal()

  const food = foods.filter(f => f.id === Number(id))[0] || null
  const isPlatter = Number.isInteger(id) ? (food && food.type === 'platter') : id === 'new-platter'

  const platterCategory = (categories.filter(category => category.name.match(/platters?/i))[0] || { id: 0 }).id

  const [name, setName] = useState(food ? food.name : '')
  const [categoryId, setCategoryId] = useState(food ? food.categoryId : (isPlatter ? platterCategory : 0))
  const [description, setDescription] = useState(food ? food.description : '')
  const [file, setFile] = useState(null)
  const [image, setImage] = useState(food ? food.image : 'https://beta.andalousgrill.com/image-uploads/863a8f7287c90c90cd31e48a06fd20cc.png')
  const [menuActive, setMenuActive] = useState(food ? food.menuActive : false)
  const [price, setPrice] = useState(food ? food.price : 0)
  const [featured, setFeatured] = useState(food ? food.featured : false)
  const [platterConfig, setPlatterConfig] = useState(food ? food.config : { type: 'platter', instructions: 'TBA', items: [], automaticItems: [], lunchHourPrice: 0 })
  if (!platterConfig.automaticItems) platterConfig.automaticItems = []

  const associatedItems = food ? items.filter(i => i.foodId === food.id) : []
  const [editedItems, setEditedItems] = useState(associatedItems)
  const shownItems = editedItems.map((item, index) => ({ ...item, index })).filter(item => item.deleted !== true)

  const saveFoodItem = (index) => (value) => {
    const newItems = [...editedItems]
    const oldItem = newItems[index]

    newItems[index] = { ...oldItem, ...value, edited: true, editing: false }
    setEditedItems(newItems)
  }
  const deleteFoodItem = (index) => () => {
    const newItems = [...editedItems]
    const oldItem = newItems[index]

    newItems[index] = { ...oldItem, deleted: true, editing: false }
    setEditedItems(newItems)
  }
  const flagFoodItem = (index) => (value) => {
    const newItems = [...editedItems]
    const oldItem = newItems[index]

    newItems[index] = { ...oldItem, editing: value }
    setEditedItems(newItems)
  }
  const addFoodItem = () => {
    const newItems = [...editedItems, {
      id: 'new',
      foodId: food ? food.id : null,
      name: '',
      price: 0,
      menuActive: false,
      platterActive: false,
      editing: true
    }]

    setEditedItems(newItems)
  }

  const updatePlatterItem = (index) => (value) => {
    const newItems = [...platterConfig.items]
    newItems[index] = value
    setPlatterConfig({ ...platterConfig, items: newItems })
  }
  const deletePlatterItem = (index) => () => {
    const newItems = [...platterConfig.items]
    newItems.splice(index, 1)
    setPlatterConfig({ ...platterConfig, items: newItems })
  }
  const createPlatterItem = () => {
    const newItems = [...platterConfig.items]
    newItems.push({
      title: '',
      category: 0,
      quantity: 1
    })
    setPlatterConfig({ ...platterConfig, items: newItems })
  }

  if (!loaded) return <LoadingScreenWithNavbar />
  if (!food && id !== 'new' && id !== 'new-platter') {
    return (
      <Page>
        <Redirect to='/menu' />
      </Page>
    )
  }

  const summarizeItemChanges = () => {
    const newItems = editedItems.filter(item => item.id === 'new' && item.edited && !item.deleted)
    const existingItems = editedItems.filter(item => item.id !== 'new')
    const deletedItems = existingItems.filter(item => item.deleted)
    const remainingItems = existingItems.filter(item => !item.deleted)
    const modifiedItems = remainingItems.filter(item => item.edited)

    return { newItems, modifiedItems, deletedItems, totalChanges: newItems.length + modifiedItems.length + deletedItems.length }
  }

  const summarizePlaterChanges = () => {
    if (!isPlatter) return []

    const oldPlatter = food ? food.config.items : []
    const newPlatter = platterConfig.items

    const changes = newPlatter.filter((item, index) => {
      const oldItem = oldPlatter[index] || {}

      if (item.title !== oldItem.title) return true
      if (item.quantity !== oldItem.quantity) return true
      if (item.category !== oldItem.category) return true

      return false
    }).map(item => ({
      ...item,
      category: (Array.isArray(item.category) ? item.category : [item.category]).map(
        c => (categories.filter(category => category.id === Number(c))[0] || { name: 'Unknown' }).name
      ).join(', ')
    }))

    // console.log(newPlatter, categories)

    return changes
  }

  const summarizeChanges = () => {
    const fieldChanges = []
    if (id !== 'new' && id !== 'new-platter') {
      if (name !== food.name) fieldChanges.push({ field: 'Name', oldValue: food.name, newValue: name })
      if (description !== food.description) fieldChanges.push({ field: 'Description', oldValue: food.description, newValue: description })
      if (menuActive !== food.menuActive) fieldChanges.push({ field: 'Enabled on Menu', oldValue: food.menuActive, newValue: menuActive })
      if (featured !== food.featured) fieldChanges.push({ field: 'Featured', oldValue: food.featured, newValue: featured })
      if (price !== food.price) fieldChanges.push({ field: 'Price', oldValue: `$${food.price.toFixed(2)}`, newValue: `$${price.toFixed(2)}` })
      if ((platterConfig.lunchHourPrice || 0) !== (food.config.lunchHourPrice || 0)) fieldChanges.push({ field: 'Lunch Hour Price', oldValue: `$${(food.config.lunchHourPrice || 0).toFixed(2)}`, newValue: `$${(platterConfig.lunchHourPrice || 0).toFixed(2)}` })
      if (isPlatter && platterConfig.automaticItems !== food.config.automaticItems) {
        fieldChanges.push({
          field: 'Automatic Items',
          oldValue: (food.config.automaticItems || []).join('\n'),
          newValue: platterConfig.automaticItems.join('\n')
        })
      }
    }

    const result = {
      fields: fieldChanges,
      image: {
        modified: food ? image !== food.image : true,
        oldValue: food ? food.image : null,
        newValue: image
      },
      items: summarizeItemChanges(),
      platter: summarizePlaterChanges()
    }

    result.didChange = fieldChanges.length > 0 || result.image.modified || result.items.totalChanges > 0 || result.platter.length
    return result
  }

  const changesReport = (changes) => {
    const listItems = (items) => (
      <table className='table is-striped is-fullwidth'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Price</th>
            <th>Appears on</th>
          </tr>
        </thead>
        <tbody>
          {
            items.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.price}</td>
                <td>{item.menuActive ? (item.platterActive ? 'Menu and platter builders' : 'Menu only') : (item.platterActive ? 'Platter builders only' : 'Nowhere')}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    )

    return (
      <>
        {
          changes.fields.length ? (
            <>
              <h2 className='subtitle'>Changed fields:</h2>
              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Field</th>
                    <th>New value</th>
                    <th>Old value</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    changes.fields.map((field, index) => (
                      <tr key={index}>
                        <td>{field.field}</td>
                        <td className='edit-food-page__summary-field-value'>
                          {
                            typeof field.newValue === 'boolean'
                              ? (field.newValue ? 'Yes' : 'No')
                              : field.newValue
                          }
                        </td>
                        <td className='edit-food-page__summary-field-value'>
                          {
                            typeof field.oldValue === 'boolean'
                              ? (field.oldValue ? 'Yes' : 'No')
                              : field.oldValue
                          }
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </>
          ) : undefined
        }
        {
          changes.image.modified ? (
            <>
              <h2 className='subtitle'>Image:</h2>
              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Old image</th>
                    <th>New image</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <img src={changes.image.oldValue} alt='' />
                    </td>
                    <td>
                      <img src={changes.image.newValue} alt='' />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : undefined
        }
        {
          changes.items.totalChanges ? (
            <>
              {
                changes.items.newItems.length ? (
                  <>
                    <h2 className='subtitle'>New items:</h2>
                    {listItems(changes.items.newItems)}
                  </>
                ) : undefined
              }
              {
                changes.items.modifiedItems.length ? (
                  <>
                    <h2 className='subtitle'>Modified items:</h2>
                    {listItems(changes.items.modifiedItems)}
                  </>
                ) : undefined
              }
              {
                changes.items.deletedItems.length ? (
                  <>
                    <h2 className='subtitle'>Deleted items:</h2>
                    {listItems(changes.items.deletedItems)}
                  </>
                ) : undefined
              }
            </>
          ) : undefined
        }
        {
          changes.platter.length ? (
            <>
              <h2 className='subtitle'>Platter builder changes:</h2>
              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    changes.platter.map((item, index) => (
                      <tr key={index}>
                        <td>{item.title}</td>
                        <td>{item.category}</td>
                        <td>{item.quantity}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </>
          ) : undefined
        }
      </>
    )
  }

  const checkEditing = async () => {
    const currentlyEditing = editedItems.filter(item => item.editing).length

    if (currentlyEditing) {
      const confirmation = await showModal(
        'Pending changes',
        `You are still editing ${currentlyEditing} food ${currentlyEditing === 1 ? 'item' : 'items'}. If you continue, your changes will be lost. Do you wish to continue?`,
        [{
          text: 'Yes, continue',
          className: 'is-danger',
          response: 'yes'
        }, {
          text: 'No, keep editing',
          response: 'no',
          isCancel: true
        }]
      )

      if (confirmation !== 'yes') return true
    }

    return false
  }

  const cancel = async () => {
    const changes = summarizeChanges()
    const creating = (id === 'new' || id === 'new-platter')

    if (await checkEditing()) return

    if (creating) {
      const confirmation = await showModal(
        'Pending changes',
        'Do you wish to discard your changes?',
        [{
          text: 'Yes, discard changes',
          className: 'is-danger',
          response: 'yes'
        }, {
          text: 'No, keep editing',
          response: 'no',
          isCancel: true
        }]
      )

      if (confirmation !== 'yes') return
    } else if (changes.didChange) {
      const confirmation = await showModal(
        'Pending changes',
        <>
          <h1 className='title'>Do you wish to discard the following changes?</h1>
          {changesReport(changes)}
        </>,
        [{
          text: 'Yes, discard changes',
          className: 'is-danger',
          response: 'yes'
        }, {
          text: 'No, keep editing',
          response: 'no',
          isCancel: true
        }]
      )

      if (confirmation !== 'yes') return
    }

    history.push('/menu')
  }

  const submit = async () => {
    const changes = summarizeChanges()
    const creating = (id === 'new' || id === 'new-platter')

    if (await checkEditing()) return

    if (!image) return showModal('Error', 'No image attached', [{ text: 'OK', response: 'ok', isCancel: true }])

    if (!creating && changes.didChange) {
      const confirmation = await showModal(
        'Confirm changes',
        <>
          <h1 className='title'>Do you wish to continue with following changes?</h1>
          {changesReport(changes)}
        </>,
        [{
          text: 'Yes, continue',
          className: 'is-primary',
          response: 'yes'
        }, {
          text: 'No, keep editing',
          response: 'no',
          isCancel: true
        }]
      )

      if (confirmation !== 'yes') return
    }

    const { action, promise } = isPlatter ? (
      creating
        ? createPlatter({ name, categoryId, description, image, menuActive, featured, price, platterConfig })
        : updatePlatter({ id, name, categoryId, description, image, menuActive, featured, price, platterConfig })
    ) : (
      creating
        ? createFood({ name, categoryId, description, image, menuActive, featured })
        : updateFood({ id, name, categoryId, description, image, menuActive, featured })
    )

    dispatch(action)
    const { ok, error, newId = (food ? food.id : null) } = await promise

    if (newId === null) {
      await showModal('Error', 'Food ID is null, cannot update items', [{ text: 'OK', response: 'ok', isCancel: true }])
    } else if (changes.items.totalChanges) {
      dispatch(createAction('menu.unload'))

      const newItems = changes.items.newItems.map(item => createItem({ ...item, foodId: (food ? food.id : newId), image: '' }))
      const modifiedItems = changes.items.modifiedItems.map(item => updateItem(item))
      const deletedItems = changes.items.deletedItems.map(item => deleteItem(item))

      const itemMap = [...changes.items.newItems, ...changes.items.modifiedItems, ...changes.items.deletedItems]

      const { actions, promises } = [...newItems, ...modifiedItems, ...deletedItems].reduce((obj, a) => {
        obj.actions.push(a.action)
        obj.promises.push(a.promise)
        return obj
      }, { actions: [], promises: [] })

      actions.map(action => dispatch(action))
      const results = await Promise.all(promises)

      const errors = results.map((result, index) => ({ result, item: itemMap[index] })).filter(({ result }) => result === false)
      const errorItems = errors.map(error => `'${error.item.name}'`).join(', ')

      if (errorItems) {
        await showModal(
          'Error at saving items',
          `Error: could not save the following items: ${errorItems}`,
          [{ text: 'OK', response: 'ok', isCancel: true }]
        )
      }
    }

    if (ok) history.push('/menu')
    else showModal('Unknown error', `Unknown error: ${error}`)
  }

  const handleDelete = async () => {
    const response = await showModal(
      'Delete Food',
      `Are you sure you want to delete '${name}'?`,
      [{
        text: 'Yes',
        className: 'is-danger',
        response: 'yes'
      }, {
        text: 'No',
        response: 'no',
        isCancel: true
      }]
    )
    if (response !== 'yes') return

    const deletedItems = associatedItems.map(item => deleteItem(item))
    const itemMap = associatedItems

    const { actions, promises } = deletedItems.reduce((obj, a) => {
      obj.actions.push(a.action)
      obj.promises.push(a.promise)
      return obj
    }, { actions: [], promises: [] })

    actions.map(action => dispatch(action))
    const results = await Promise.all(promises)

    const errors = results.map((result, index) => ({ result, item: itemMap[index] })).filter(({ result }) => result === false)
    const errorItems = errors.map(error => `'${error.item.name}'`).join(', ')

    if (errorItems) {
      await showModal(
        'Error at deleting items',
        `Aborting delete: could not delete the following items: ${errorItems}`,
        [{ text: 'OK', response: 'ok', isCancel: true }]
      )
    }

    const { action, promise } = deleteFood({ id })

    dispatch(action)
    await promise

    history.push('/menu')
  }

  const upload = async () => {
    if (!file) return
    setImage(null)

    const [error, uploaded] = await uploadImage(file, token, dispatch)[unwrap]
    if (error) {
      return showModal(
        'Error',
        `Error while uploading image: ${error.message}`
      )
    }

    setImage(uploaded.publicUrl)
  }

  return (
    <Page>
      <h1 className='title'>{
        Number.isInteger(id)
          ? `Editing ${isPlatter ? 'platter builder' : 'food'}: ${food.name}`
          : `Add new ${isPlatter ? 'platter builder' : 'food'}`
      }
      </h1>

      <div className='field'>
        <label className='label'>Name</label>
        <div className='control'>
          <input className='input' type='text' placeholder='Name' value={name} onChange={(event) => setName(event.target.value)} />
        </div>
      </div>

      {
        isPlatter ? (
          <>
            <div className='field'>
              <label className='label'>Price ($)</label>
              <div className='control'>
                <NumberInputManaged priceInput value={price} onChange={(event) => setPrice(event.target.value)} buttonsHidden />
              </div>
            </div>
            <div className='field'>
              <label className='label'>Lunch Hour Price ($)</label>
              <div className='control'>
                <NumberInputManaged
                  priceInput value={platterConfig.lunchHourPrice || 0} onChange={(event) => setPlatterConfig(old => ({
                    ...old,
                    lunchHourPrice: event.target.value
                  }))} buttonsHidden
                />
              </div>
              <p className='help'>Set this to $0 to use the regular price in lunch hour (11 AM - 2 PM Monday to Friday)</p>
            </div>
          </>
        ) : undefined
      }

      <div className='field'>
        <label className='label'>Category</label>
        <div className='control'>
          <div className='select'>
            <select value={categoryId} onChange={event => setCategoryId(event.target.value)}>
              <option value={0}>Please select a category</option>
              {
                categories.map(category => <option key={category.id} value={category.id}>{category.name}</option>)
              }
            </select>
          </div>
        </div>
      </div>

      <div className='field'>
        <label className='label'>Description</label>
        <div className='control'>
          <textarea className='textarea' placeholder='Description' value={description} onChange={(event) => setDescription(event.target.value)} />
        </div>
      </div>

      <div className='field'>
        <label className='label'>Settings</label>
        {
          isPlatter ? (
            <>
              <div className='control'>
                <label className='checkbox'>
                  <input type='checkbox' checked={menuActive} onChange={() => setMenuActive(!menuActive)} />
                  &nbsp;
                  Enable for online ordering
                </label>
              </div>
            </>
          ) : undefined
        }
        <div className='control'>
          <label className='checkbox'>
            <input type='checkbox' checked={featured} onChange={() => setFeatured(!featured)} />
            &nbsp;
            Feature on website Menu page
          </label>
        </div>
      </div>

      <div className='field'>
        <label className='label'>Image</label>
        <div className='control'>
          <div className='card edit-food-page__image-card'>
            <div className='card-image'>
              <figure className='image is-1by1'>
                {
                  image
                    ? <img src={image} alt='food' />
                    : <img src='https://beta.andalousgrill.com/image-uploads/863a8f7287c90c90cd31e48a06fd20cc.png' alt='not set' />
                }
              </figure>
            </div>
            <div className='card-content edit-food-page__image-card-content'>
              <div className='level is-mobile'>
                <div className='level-left'>
                  <FileInput onChange={(file) => setFile(file)} />
                </div>
                <div className='level-right'>
                  <button className='button is-primary' onClick={upload}>
                    Upload image
                  </button>
                </div>
              </div>
              <div className='edit-food-page__notice'>
                Image (600x600)
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='is-divider' />

      <div className='edit-food-page__configurator'>
        {
          isPlatter ? (
            <>
              <h2 className='subtitle'>{
                Number.isInteger(id)
                  ? `Items on platter builder '${food.name}'`
                  : 'Add items to platter builder'
              }
              </h2>

              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>Category</th>
                    <th>Quantity</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    platterConfig.items.map((item, index) => (
                      <PlatterItemRow
                        key={index}
                        item={item}
                        onUpdate={updatePlatterItem(index)}
                        onDelete={deletePlatterItem(index)}
                      />
                    ))
                  }
                </tbody>
              </table>

              <div>
                <button className='button is-primary' onClick={createPlatterItem}>
                  <span className='icon'>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                &nbsp;
                Add item
                </button>
              </div>

              <div style={{ height: '2em' }} /> {/* spacer */}

              <h2 className='subtitle'>Automatic Items:</h2>

              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    platterConfig.automaticItems.map((item, index) => {
                      const rewriteItem = (value) => setPlatterConfig(old => ({
                        ...old,
                        automaticItems: old.automaticItems.map((v, i) => {
                          return (i !== index) ? v : value
                        })
                      }))

                      const deleteItem = () => setPlatterConfig(old => ({
                        ...old,
                        automaticItems: [...old.automaticItems.slice(0, index), ...old.automaticItems.slice(index + 1)]
                      }))

                      return (
                        <tr key={index}>
                          <td>
                            <input className='input' value={item} onChange={event => rewriteItem(event.target.value)} />
                          </td>
                          <td>
                            <button className='button is-danger' onClick={deleteItem}>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                              &nbsp;
                              Delete
                            </button>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>

              <div>
                <button className='button is-primary' onClick={() => setPlatterConfig(old => ({ ...old, automaticItems: [...old.automaticItems, ''] }))}>
                  <span className='icon'>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                &nbsp;
                Add automatic item
                </button>
              </div>
            </>
          ) : (
            <>
              <h2 className='subtitle'>{
                Number.isInteger(id)
                  ? `Items associated with food '${food.name}'`
                  : 'Add items to food'
              }
              </h2>

              <table className='table is-striped is-fullwidth'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Price ($)</th>
                    <th>Enable on Menu</th>
                    <th>Enable on Platter builders</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    shownItems.map((item, index) => (
                      <FoodItemRow
                        item={item}
                        key={index}
                        onSave={saveFoodItem(item.index)}
                        onDelete={deleteFoodItem(item.index)}
                        onEditing={flagFoodItem(item.index)}
                      />
                    ))
                  }
                </tbody>
              </table>

              <div>
                <button className='button is-primary' onClick={addFoodItem}>
                  <span className='icon'>
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                &nbsp;
                Add item
                </button>
              </div>
            </>
          )
        }
      </div>

      <div className='is-divider' />

      <div className='level'>
        <div className='level-left'>
          {
            !Number.isInteger(id)
              ? undefined
              : (
                <>
                  <div className='field is-grouped'>
                    <p className='control'>
                      <button className='button is-danger' onClick={handleDelete}>Delete</button>
                    </p>
                  </div>
                </>
              )
          }
        </div>
        <div className='level-right'>
          <div className='field is-grouped is-grouped-right'>
            <p className='control'>
              <button className='button' onClick={cancel}>Cancel</button>
            </p>
            <p className='control'>
              <button className='button is-primary' onClick={submit}>Submit</button>
            </p>
          </div>
        </div>
      </div>

      {modal}
    </Page>
  )
}

export default connect(
  state => ({
    foods: state.menu.foods,
    items: state.menu.items,
    categories: state.menu.categories,
    loaded: state.menu.loaded,
    token: state.user.token
  }),
  dispatch => ({
    dispatch: (action) => dispatch(action)
  })
)(withUser(EditFoodPage))
