const initialState = {
  loggedIn: false,
  username: null,
  token: null,
  group: null,
  lastTested: 0,
  loginForm: {
    username: '',
    password: '',
    error: '',
    inProgress: false,
    redirect: ''
  }
}

const userReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'user.login') {
    return {
      ...state,
      username: action.username,
      token: action.token,
      group: action.group,
      loggedIn: true,
      lastTested: 0,
      loginForm: {
        ...state.loginForm,
        username: '',
        password: '',
        error: '',
        inProgress: false
      }
    }
  }
  if (type === 'user.logout') {
    return {
      ...state,
      username: null,
      token: null,
      group: null,
      loggedIn: false,
      loginForm: {
        ...state.loginForm,
        username: '',
        password: '',
        error: '',
        inProgress: false,
        redirect: ''
      }
    }
  }

  if (type === 'user.loginError') {
    return {
      ...state,
      loggedIn: false,
      loginForm: {
        ...state.loginForm,
        error: action.error,
        inProgress: false,
        username: action.clear ? '' : state.loginForm.username,
        password: action.clear ? '' : state.loginForm.password
      }
    }
  }

  if (type === 'user.loginInProgress') {
    return {
      ...state,
      loggedIn: false,
      loginForm: {
        ...state.loginForm,
        error: '',
        inProgress: true
      }
    }
  }

  if (type === 'user.loginTested') {
    return {
      ...state,
      lastTested: action.timestamp
    }
  }

  if (type === 'user.setLoginRedirect') {
    return {
      ...state,
      loginForm: {
        ...state.loginForm,
        redirect: action.redirect
      }
    }
  }

  if (type === 'user.setUsername') {
    return {
      ...state,
      loginForm: {
        ...state.loginForm,
        username: action.username
      }
    }
  }

  if (type === 'user.setPassword') {
    return {
      ...state,
      loginForm: {
        ...state.loginForm,
        password: action.password
      }
    }
  }

  return state
}

export default userReducer
