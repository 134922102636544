/* globals Event */
import React, { useState, useRef, useEffect } from 'react'

import { useModal } from '../components'

import * as hardwarePrinter from '../util/printer'

const usePrinter = () => {
  const [settings, setSettings] = useState({ content: null, resolve: null, reject: null })
  const ref = useRef()

  const [modal, showModal] = useModal()

  useEffect(() => {
    if (!settings.resolve || !ref.current) return

    const printedHTML = ref.current.innerHTML
    const windowOrigin = window.location.origin.toString()
    const blob = new window.Blob([printedHTML], { type: 'text/html' })
    const blobUrl = URL.createObjectURL(blob)

    const iframe = document.createElement('iframe')
    iframe.src = blobUrl
    iframe.classList.add('printer__iframe')

    console.log('[printer] iframe added, loading')

    iframe.onload = async () => {
      console.log('[printer] iframe loaded, cloning elements')

      const iframeDocument = iframe.contentDocument
      iframeDocument.documentElement.style.fontSize = '8pt'
      iframeDocument.body.style.height = 'auto'

      const nodesToClone = document.querySelectorAll('style, link:not([rel="shortcut icon"])')

      const nodesLoaded = Array.from(nodesToClone).map(node => new Promise((resolve, reject) => {
        const clonedNode = node.cloneNode(true)

        if (clonedNode.nodeName === 'LINK' && clonedNode.attributes.href.value[0] === '/') {
          const clonedHref = clonedNode.attributes.href.value
          clonedNode.attributes.href.value = windowOrigin + clonedHref
        }

        clonedNode.onload = () => resolve()

        iframeDocument.body.appendChild(clonedNode)
      }))

      await Promise.all(nodesLoaded)
      console.log('[printer] cloned nodes loaded')

      setTimeout(async () => {
        if (!hardwarePrinter.available) {
          iframe.contentWindow.print()
          // settings.resolve({ success: true })
        } else {
          try {
            const fullBlob = new window.Blob([iframe.contentDocument.documentElement.outerHTML], { type: 'text/html' })
            const fullBlobUrl = URL.createObjectURL(fullBlob)

            const result = await hardwarePrinter.print(fullBlobUrl)
            if (!result.success) throw new Error(result.failureReason || 'Unknown error')

            console.log('printing successful', result)
            settings.resolve(result)
          } catch (err) {
            showModal(
              'Error at printing',
              err.message
            )

            settings.reject(err)
          }
        }

        iframe.parentNode.removeChild(iframe)
        window.dispatchEvent(new Event('afterprint'))
      }, 500)
    }

    document.body.appendChild(iframe)
  }, [settings]) // eslint-disable-line

  const printer = (
    <>
      <div className='printer' ref={ref}>
        <div className='printer__content'>
          <div className='printer__content-inner'>
            {settings.content}
          </div>
        </div>
      </div>
      {modal}
    </>
  )

  const print = (contentToPrint) => new Promise((resolve, reject) => {
    console.log('[printer] print initiated')

    return setSettings({
      content: contentToPrint,
      resolve,
      reject
    })
  })

  return [printer, print]
}

export default usePrinter
