import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import xkcd1179 from '../util/xkcd1179'

import createAction from '../actions'
import { loadCalendarEvents } from '../actions/calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt, faFilter } from '@fortawesome/free-solid-svg-icons'

/* eslint-disable jsx-a11y/anchor-is-valid */ // eslint-disable-line
//                                              ^ stop linters from fighting

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

const EventsCalendar = ({ onFilter }) => {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.calendar.selected)

  const calendarLocation = useSelector(state => state.calendar.location)
  const setCalendarLocation = event => dispatch(createAction('calendar.setLocation', { location: event.target.value }))

  const calendarEvents = useSelector(state => state.calendar.events.list).filter(event => {
    if (calendarLocation === 'All Locations') return true

    return event.config.location.name === calendarLocation
  })

  const loaded = useSelector(state => state.calendar.events.loaded)

  const selectedYear = selected.year
  const selectedMonth = selected.month

  useEffect(() => {
    dispatch(loadCalendarEvents())
  }, [dispatch, selectedYear, selectedMonth])

  const firstDay = new Date(selectedYear, selectedMonth, 1)
  const lastDay = new Date(selectedYear, selectedMonth + 1, 0)

  const weeks = [[null, null, null, null, null, null, null]]
  weeks[0][firstDay.getDay()] = 1

  for (let i = 2; i <= lastDay.getDate(); i++) {
    const day = new Date(selectedYear, selectedMonth, i).getDay()
    if (day === 0) weeks.push([null, null, null, null, null, null, null])
    weeks[weeks.length - 1][day] = i
  }

  const Day = ({ day, index }) => {
    const date = day != null ? xkcd1179(new Date(selectedYear, selectedMonth, day)) : null
    const eventsThatDay = calendarEvents.filter(event => event.date === date)

    const eventCount = eventsThatDay.length
    const textFormat = eventCount === 1 ? `${eventCount} event` : `${eventCount} events`

    const hasStatus = status => Boolean(eventsThatDay.filter(event => event.status === status).length)

    let displayStyle = null
    if (eventCount) displayStyle = 'is-dark'
    if (hasStatus('CLOSED')) displayStyle = 'is-light'
    if (hasStatus('CANCELLED')) displayStyle = 'is-light'
    if (hasStatus('CONFIRMED')) displayStyle = 'is-success'
    if (hasStatus('NEW')) displayStyle = 'is-danger'
    if (hasStatus('VIEWED')) displayStyle = 'is-warning'
    if (hasStatus('CONTACT CUSTOMER')) displayStyle = 'is-warning'
    if (hasStatus('CONTRACT')) displayStyle = 'is-warning'
    if (selected.date === date) displayStyle = 'is-primary'

    // const isSelected = selected.date === date
    // const hasCancelled = !isSelected && Boolean(eventsThatDay.filter(event => event.status === 'CANCELLED').length)
    // const hasNew = !(isSelected || hasCancelled) && Boolean(eventsThatDay.filter(event => event.status === 'CONFIRMED').length)
    // const hasConfirmed = !(isSelected || hasCancelled || hasNew) && Boolean(eventCount)

    const selectDate = () => dispatch(createAction('calendar.selectDate', { date }))

    return (
      <a tabIndex={0} className={classNames('events-calendar__day button', displayStyle)} disabled={day == null} onClick={date ? selectDate : undefined}>
        <div className='events-calendar__day-number'>{day || '\xa0'}</div>
        <div className='events-calendar__day-name'>{dayNames[index]}</div>
        <div className='events-calendar__day-placeholder' />
        <div className='events-calendar__day-events'>
          {eventCount ? textFormat : undefined}
        </div>
      </a>
    )
  }

  const previousMonth = new Date(selectedYear, selectedMonth - 1)
  const nextMonth = new Date(selectedYear, selectedMonth + 1)

  const previousDec = new Date(selectedYear, -1)
  const nextJan = new Date(selectedYear, 12)

  const gotoDate = (date) => () => dispatch(createAction('calendar.selectMonth', { year: date.getFullYear(), month: date.getMonth() }))

  return (
    <div className='events-calendar'>
      <nav className='pagination is-centered' role='navigation' aria-label='pagination'>
        {/* <a tabIndex={0} className='pagination-previous' onClick={gotoDate(previousMonth)}>Prev</a> */}
        {/* <a tabIndex={0} className='pagination-next' onClick={gotoDate(nextMonth)}>Next</a> */}
        <ul className='pagination-list'>
          <li><a tabIndex={0} className='pagination-link' onClick={gotoDate(previousDec)}>{previousDec.getFullYear()}</a></li>
          <li><span className='pagination-ellipsis'>&hellip;</span></li>
          <li><a tabIndex={0} className='pagination-link' onClick={gotoDate(previousMonth)}>{monthNames[previousMonth.getMonth()]}</a></li>
          <li><a tabIndex={0} className='pagination-link is-current'>{monthNames[selectedMonth]} {selectedYear}</a></li>
          <li><a tabIndex={0} className='pagination-link' onClick={gotoDate(nextMonth)}>{monthNames[nextMonth.getMonth()]}</a></li>
          <li><span className='pagination-ellipsis'>&hellip;</span></li>
          <li><a tabIndex={0} className='pagination-link' onClick={gotoDate(nextJan)}>{nextJan.getFullYear()}</a></li>
        </ul>
      </nav>
      <div className='events-calendar__weeks'>
        {
          weeks.map((week, index) => (
            <div key={index} className='events-calendar__week'>
              {
                week.map((day, index) => <Day key={index} day={day} index={index} />)
              }
            </div>
          ))
        }
      </div>
      <div className='events-calendar__filter-button'>
        <button className='button is-primary' onClick={onFilter}>
          <FontAwesomeIcon icon={faFilter} />
          &nbsp;
          Advanced Filtering
        </button>
        &nbsp;
        &nbsp;
        <div className='select'>
          <select value={calendarLocation} onChange={setCalendarLocation}>
            <option value='All Locations'>All Locations</option>
            <option value='Las Colinas'>Las Colinas</option>
            <option value='Arlington'>Arlington</option>
            <option value='Richardson'>Richardson</option>
          </select>
        </div>
      </div>
      <div className={classNames('events-calendar__overlay', {
        'events-calendar__overlay--enabled': !loaded
      })}
      >
        <FontAwesomeIcon icon={faSyncAlt} spin size='10x' />
      </div>
    </div>
  )
}

export default EventsCalendar
