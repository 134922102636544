import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'

import { Page, withUser } from '../containers'

import { loadOrders } from '../actions/orders'
import { loadConfig, saveConfig } from '../actions/configs'
import { loadMenu } from '../actions/menu'

const defaultMotd = {
  title: '',
  message: '',
  active: false
}
const defaultSettings = {
  // remindForAppetizer: false,
  // remindForDessert: false
  addPitasToDips: true,
  checkoutReminders: [-1, -1, -1]
}

const HomePage = ({ orders, loadOrders, configs, loadConfig, saveConfig, menu, loadMenu }) => {
  useEffect(() => {
    if (!orders.loaded) loadOrders()

    if (!configs.motd) loadConfig('motd')
    if (!configs.settings) loadConfig('settings')

    if (!menu.loaded) loadMenu()
  }, []) // eslint-disable-line

  const [configsLoaded, setConfigsLoaded] = useState([])
  const [motd, setMotd] = useState(defaultMotd)
  const [settings, setSettings] = useState(defaultSettings)

  if (!configsLoaded.includes('motd') && configs.motd) {
    setConfigsLoaded([...configsLoaded, 'motd'])
    setMotd(configs.motd.value || defaultMotd)
  }

  if (!configsLoaded.includes('settings') && configs.settings) {
    setConfigsLoaded([...configsLoaded, 'settings'])
    setSettings(configs.settings.value || defaultSettings)
  }

  const handleMotdField = (field) => (event) => setMotd({ ...motd, [field]: event.target.value })
  const flipMotdField = (field) => () => setMotd({ ...motd, [field]: !motd[field] })
  const flipSettingsField = (field) => () => setSettings({ ...settings, [field]: !settings[field] })

  const handleMotdReset = () => setMotd(configs.motd.value || defaultMotd)
  const handleMotdSave = () => saveConfig({
    name: 'motd',
    value: motd,
    meta: { public: true }
  })

  const handleSettingsReset = () => setSettings(configs.settings.value || defaultSettings)
  const handleSettingsSave = () => saveConfig({
    name: 'settings',
    value: settings,
    meta: { public: true }
  })

  const CheckoutReminder = ({ index }) => {
    if (!menu.loaded) return <button className='button is-loading' disabled />

    const reminders = [...(settings.checkoutReminders || [-1, -1, -1])]
    const save = (event) => {
      const value = Number(event.target.value)
      reminders[index] = value

      setSettings({ ...settings, checkoutReminders: reminders })
    }

    return (
      <div className='select'>
        <select value={reminders[index]} onChange={save}>
          <option value={-1}>None</option>
          {
            menu.items
              .filter(item => item.menuActive)
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))
          }
        </select>
      </div>
    )
  }

  return (
    <Page>
      <div className='columns is-multiline'>
        <div className='column is-half'>
          {/* announcement settings */}
          <div className='card home-page__card'>
            <div className='card-content'>
              <h1 className='title'>Announcement</h1>
              <div className='field'>
                <label className='label'>Title</label>
                <div className='control'>
                  <input className='input' type='text' placeholder='New Location!' value={motd.title} onChange={handleMotdField('title')} />
                </div>
              </div>
              <div className='field'>
                <label className='label'>Message</label>
                <div className='control'>
                  <input className='input' type='text' placeholder='Restaurant is open in Rivendell' value={motd.message} onChange={handleMotdField('message')} />
                </div>
              </div>
              <div className='field'>
                <div className='control'>
                  <label className='checkbox'>
                    <input type='checkbox' checked={motd.active} onChange={flipMotdField('active')} />
                    &nbsp;
                    Show announcement on <a href='https://andalousgrill.com' target='_blank' rel='noopener noreferrer'>homepage</a>
                  </label>
                </div>
              </div>
              <div className='field is-grouped is-grouped-right'>
                <div className='control'>
                  <button
                    className={classNames('button', {
                      'is-loading': configs.motd == null
                    })} disabled={configs.motd == null} onClick={handleMotdReset}
                  >Reset
                  </button>
                </div>
                <div className='control'>
                  <button
                    className={classNames('button is-primary', {
                      'is-loading': configs.motd == null
                    })} disabled={configs.motd == null} onClick={handleMotdSave}
                  >Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* general settings */}
          <div className='card home-page__card'>
            <div className='card-content'>
              <h1 className='title'>General Settings</h1>
              {/* <div className='field'>
                <div className='control'>
                  <label className='checkbox'>
                    <input type='checkbox' onChange={flipSettingsField('remindForAppetizer')} checked={settings.remindForAppetizer} />
                    &nbsp;
                    Display Appetizer Reminder on customer checkout
                  </label>
                </div>
              </div>
              <div className='field'>
                <div className='control'>
                  <label className='checkbox'>
                    <input type='checkbox' onChange={flipSettingsField('remindForDessert')} checked={settings.remindForDessert} />
                    &nbsp;
                    Display Dessert Reminder on customer checkout
                  </label>
                </div>
              </div> */}
              <div className='field'>
                <div className='control'>
                  <label className='checkbox'>
                    <input type='checkbox' onChange={flipSettingsField('addPitasToDips')} checked={settings.addPitasToDips || false} />
                    &nbsp;
                    Include a pita with dips in 12oz, 16oz, and 32oz containers
                  </label>
                </div>
              </div>
              <div className='field'>
                <label className='label'>Checkout Reminders</label>
                <div className='control'>
                  <div className='home-page__checkout-reminder'>
                    <CheckoutReminder index={0} />
                  </div>
                  <div className='home-page__checkout-reminder'>
                    <CheckoutReminder index={1} />
                  </div>
                  <div className='home-page__checkout-reminder'>
                    <CheckoutReminder index={2} />
                  </div>
                </div>
              </div>
              <div className='field is-grouped is-grouped-right'>
                <div className='control'>
                  <button
                    className={classNames('button', {
                      'is-loading': configs.settings == null
                    })} disabled={configs.settings == null} onClick={handleSettingsReset}
                  >Reset
                  </button>
                </div>
                <div className='control'>
                  <button
                    className={classNames('button is-primary', {
                      'is-loading': configs.settings == null
                    })} disabled={configs.settings == null} onClick={handleSettingsSave}
                  >Save
                  </button>
                </div>
              </div>
            </div>
          </div>
          {/* coupon codes */}
          {/* <div className='card home-page__card'>
            <div className='card-content'>
              <h1 className='title'>Active Coupon Codes</h1>
              <table className='table is-striped is-hoverable is-fullwidth'>
                <thead>
                  <tr>
                    <th>Code</th>
                    <th>Type</th>
                    <th>Effect</th>
                    <th>Minimum</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><strong>LEVIOSA</strong></td>
                    <td>Percent</td>
                    <td>10%</td>
                    <td>$5.00</td>
                  </tr>
                  <tr>
                    <td><strong>SPOOKY</strong></td>
                    <td>Cashed</td>
                    <td>$5.00</td>
                    <td>$25.00</td>
                  </tr>
                </tbody>
              </table>
              <div className='field is-grouped is-grouped-right'>
                <div className='control'>
                  <Link to='/coupons' className='button is-primary'>Edit Coupon Codes</Link>
                </div>
              </div>
            </div>
          </div> */}
          {/* admin app download */}
          <div className='card home-page__card'>
            <div className='card-content'>
              <h1 className='title'>Admin App Installer</h1>
              <div className='field is-grouped is-grouped-right'>
                <div className='control'>
                  <a href='/andalous-admin-setup.exe' download className='button is-primary'>Download Admin App</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='column is-half'>
          {/* latest orders */}
          <div className='card home-page__card'>
            <div className='card-content'>
              <h1 className='title'>Latest Orders</h1>
              {
                orders.loaded ? (
                  <table className='table is-striped is-hoverable is-fullwidth'>
                    <thead>
                      <tr>
                        <th>Location</th>
                        <th>Number</th>
                        <th>Name</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orders.list.filter(order => order.status !== 'UNCONFIRMED').slice(0, 10).map(order => (
                        <tr key={order.id}>
                          <td>{order.config.location.name}</td>
                          <td>{order.id}</td>
                          <td>{order.config.name}</td>
                          <td>${order.config.price.toFixed(2)}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <div className='has-text-centered'>
                    <FontAwesomeIcon icon={faSyncAlt} spin size='10x' />
                  </div>
                )
              }
              <div className='field is-grouped is-grouped-right'>
                <div className='control'>
                  <Link to='/orders' className='button is-primary'>Go to Orders</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default connect(
  state => ({
    orders: { list: state.orders.list, loaded: state.orders.loaded },
    configs: state.configs.list,
    menu: state.menu
  }),
  dispatch => ({
    loadOrders: () => dispatch(loadOrders()),
    loadConfig: (name) => dispatch(loadConfig(name)),
    saveConfig: (config) => dispatch(saveConfig(config)),
    loadMenu: () => dispatch(loadMenu())
  })
)(withUser(HomePage))
