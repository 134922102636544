import React, { useCallback, useState } from 'react'
import bulmaCalendar from 'bulma-calendar'
import xkcd1179 from '../util/xkcd1179'

const DateInput = ({ value, defaultValue, onChange, inline }) => {
  const [currentValue, setCurrentValue] = useState(new Date((value || defaultValue || xkcd1179(new Date())) + ' 0:00'))
  const [calendarInput, setCalendarInput] = useState(null)

  const [onChangeContainer] = useState({ onChange: null })
  onChangeContainer.onChange = onChange

  const calendarRef = useCallback(input => {
    if (!input) return

    // console.log(currentValue)

    const calendarInput = bulmaCalendar.attach(input, {
      type: 'date',
      displayMode: inline ? 'inline' : 'default',
      startDate: new Date(currentValue) // newsflash, if I have to find out about this through using inspector on your friggin documentation THEN IT'S A TERRIBLE DOCUMENTATION AND YOU SHOULD FEEL BAD (because I sure did while debugging this)
    })[0]
    setCalendarInput(calendarInput)

    // why you gotta be so weird...
    calendarInput.on('select', (e) => {
      if (!e.data.date) return // because OBVIOUSLY you would trigger an event with no data, why not indeed

      const date = new Date(e.data.startDate) // very obvious place to put that value, at least it's documented...

      setCurrentValue(date)
      if (onChange) setTimeout(() => onChangeContainer.onChange({ value: date, target: { value: date } }), 0)
    })
  }, []) // eslint-disable-line
  //         ^ I know what I'm doing         <-- famous last words

  if (value && calendarInput) {
    const dateValue = new Date(value + ' 0:00')
    if (xkcd1179(dateValue) !== xkcd1179(currentValue)) {
      calendarInput.value(dateValue)
    }
  }

  return <input className='input' type='date' ref={calendarRef} />
}

export default DateInput
