import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from 'react-redux'
import { MultiSelect } from 'react-sm-select'

import { NumberInputManaged, useModal } from '../components'

const PlatterItemRow = ({ item, onUpdate, onDelete }) => {
  const categories = useSelector(state => state.menu.categories)

  const [modal, showModal] = useModal()

  const updateItem = (modifications) => {
    const newItem = { ...item, ...modifications }
    if (onUpdate) onUpdate(newItem)
  }

  const updateTitle = (event) => updateItem({ title: event.target.value })
  const updateCategory = (value) => updateItem({ category: value })
  const updateQuantity = (event) => updateItem({ quantity: event.target.value })

  const deleteItem = async () => {
    const response = await showModal(
      'Delete item',
      `Delete item '${item.title}' from platter?`,
      [{
        text: 'Yes',
        className: 'is-danger',
        response: 'yes'
      }, {
        text: 'No',
        response: 'no',
        isCancel: true
      }]
    )

    if (response !== 'yes') return
    if (onDelete) onDelete()
  }

  const multiSelectOptions = categories.map(category => ({
    value: category.id, label: category.name
  }))
  const multiSelectValue = Array.isArray(item.category) ? item.category : [item.category]

  return (
    <tr>
      <td>
        <input className='input' placeholder='Title' value={item.title} onChange={updateTitle} />
      </td>
      <td>
        <div className='field'>
          <div className='control' style={{ minWidth: '15em' }}>
            <MultiSelect
              options={multiSelectOptions}
              value={multiSelectValue}
              onChange={updateCategory}
            />
          </div>
          {/* <select value={item.category} onChange={updateCategory}>
            <option value={0}>Select a category</option>
            {
              categories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)
            }
          </select> */}
        </div>
      </td>
      <td>
        <NumberInputManaged value={item.quantity} onChange={updateQuantity} minValue={1} />
      </td>
      <td>
        <button className='button is-danger' onClick={deleteItem}>
          <span className='icon'>
            <FontAwesomeIcon icon={faTrash} />
          </span>
          &nbsp;
          Delete
        </button>
        {modal}
      </td>
    </tr>
  )
}

export default PlatterItemRow
