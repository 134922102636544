import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { connect } from 'react-redux'
import { available as printerAvailable } from '../util/printer'

import createAction from '../actions'

class MenuBar extends React.Component {
  constructor () {
    super()

    this.state = {
      expanded: false
    }

    this.handleToggleExpanded = () => this.setState(state => ({ ...state, expanded: !state.expanded }))
  }

  render () {
    const { expanded } = this.state

    const isAllowed = this.props.withUser__group === 'admin'

    const navigationLinks = isAllowed ? (
      <>
        <Link to='/' className='navbar-item'>Home</Link>

        <Link to='/orders' className='navbar-item'>Orders</Link>
        <Link to='/kitchen' className='navbar-item'>Kitchens</Link>
        <Link to='/events' className='navbar-item'>Events</Link>
        <Link to='/menu' className='navbar-item'>Menu</Link>
        {/* <Link to='/coupons' className='navbar-item'>Coupons</Link> */}
        <Link to='/catering' className='navbar-item'>Catering</Link>
        <Link to='/users' className='navbar-item'>Users</Link>
        <Link to='/reports' className='navbar-item'>Reports</Link>
        {
          !printerAvailable ? undefined : (
            <Link to='/printer' className='navbar-item'>Printer Settings</Link>
          )
        }
      </>
    ) : (
      <>
        <Link to='/kitchen' className='navbar-item'>Kitchens</Link>
        <Link to='/events' className='navbar-item'>Events</Link>
        {
          !printerAvailable ? undefined : (
            <Link to='/printer' className='navbar-item'>Printer Settings</Link>
          )
        }
      </>
    )

    return (
      <nav className='navbar menu-bar' role='navigation' aria-label='main navigation'>
        <div className='navbar-brand'>
          {/* eslint-disable-next-line */}
          <a className='navbar-item'>
            <img src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-tagged-regged-194w.png' alt='' />
          </a>

          {/* eslint-disable-next-line */}
          <a role='button' className={classNames('navbar-burger burger', {
            'is-active': expanded
          })} onClick={this.handleToggleExpanded} aria-label='menu' aria-expanded='false'
          >
            <span aria-hidden='true' />
            <span aria-hidden='true' />
            <span aria-hidden='true' />
          </a>
        </div>

        <div className={classNames('navbar-menu', {
          'is-active': expanded
        })}
        >
          <div className='navbar-start'>
            {navigationLinks}
          </div>
          <div className='navbar-end'>
            <div className='navbar-item'>
              <div className='buttons'>
                <button type='button' className='button is-light' onClick={() => this.props.withUser__logout()}>
                  Log out
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default connect(
  state => ({
    withUser__group: state.user.group
  }),
  dispatch => ({
    withUser__logout: () => dispatch(createAction('user.logout'))
  }))(MenuBar)
