const initialState = {
  list: [],
  loaded: false
}

const usersReducer = (state = initialState, action) => {
  const type = action.type

  if (type === 'users.load') return { ...state, list: action.list, loaded: true }
  if (type === 'users.unload') return { ...state, list: [], loaded: false }

  return state
}

export default usersReducer
