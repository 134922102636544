import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faLock, faTrash, faTimesCircle, faSave, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { useModal, usePasswordInput } from '../components'

import { changeUser, updatePassword, deleteUser } from '../actions/users'

const groups = {
  admin: 'Administrator',
  manager: 'Kitchen Manager'
}

const UserRow = ({ user }) => {
  const dispatch = useDispatch()

  const [editingState, setEditingState] = useState({
    editing: false,
    name: user.name,
    login: user.login,
    group: user.group
  })
  const [passwordPopup, setPasswordPopup] = useState(false)
  const [passwordLoading, setPasswordLoading] = useState(false)
  const [modal, showModal] = useModal()

  const [passwordInput, getPassword, resetPassword] = usePasswordInput()

  const resetEditingState = (editing) => setEditingState({
    editing,
    name: user.name,
    login: user.login,
    group: user.group
  })

  const startEditing = () => resetEditingState(true)
  const cancelEditing = () => resetEditingState(false)

  const editName = (event) => setEditingState({ ...editingState, name: event.target.value })
  const editLogin = (event) => setEditingState({ ...editingState, login: event.target.value })
  const editGroup = (event) => setEditingState({ ...editingState, group: event.target.value })

  const startPassword = () => {
    resetPassword()
    setPasswordPopup(true)
  }
  const cancelPassword = () => {
    setPasswordPopup(false)
    resetPassword()
  }

  const saveUser = async () => {
    const { action, promise } = changeUser({
      id: user.id,
      name: editingState.name,
      login: editingState.login,
      group: editingState.group
    })
    dispatch(action)
    const { ok, error } = await promise

    if (ok) cancelEditing()
    else {
      await showModal(
        'Error',
        `Unknown error while saving user ${user.name}: ${error}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setPasswordLoading(false)
    }
  }

  const savePassword = async () => {
    const { password, ready } = getPassword()
    if (!ready) return console.error('Password not ready yet')

    setPasswordLoading(true)

    const { action, promise } = updatePassword({
      id: user.id,
      password
    })
    dispatch(action)
    const { ok, error } = await promise

    if (ok) {
      setPasswordPopup(false)
      setPasswordLoading(false)
      showModal(
        'Password saved',
        `New password for user ${user.name} successfully saved`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
    } else {
      await showModal(
        'Error',
        `Unknown error while saving password for user ${user.name}: ${error}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setPasswordLoading(false)
    }
  }

  const handleDelete = async () => {
    const response = await showModal(
      'Delete user',
      `Are you sure you want to delete user ${user.name}? Warning: this action is irreversible.`,
      [{
        text: `Yes, delete ${user.name}`,
        response: 'yes',
        className: 'is-danger'
      }, {
        text: 'No, keep this user',
        response: 'no',
        isCancel: true
      }]
    )

    if (response !== 'yes') return

    const { action, promise } = deleteUser({
      id: user.id
    })
    dispatch(action)
    const { ok, error } = await promise

    if (!ok) {
      await showModal(
        'Error',
        `Unknown error while saving user ${user.name}: ${error}`,
        [{
          text: 'OK',
          response: 'ok',
          isCancel: true
        }]
      )
      setPasswordLoading(false)
    }
  }

  return (
    <tr className='user-row'>
      <td className='user-row__cell'>
        {
          editingState.editing
            ? <input className='input' value={editingState.name} onChange={editName} />
            : user.name
        }
      </td>
      <td className='user-row__cell'>
        {
          editingState.editing
            ? <input className='input' value={editingState.login} onChange={editLogin} />
            : user.login
        }
      </td>
      <td className='user-row__cell'>
        {
          editingState.editing ? (
            <div className='select'>
              <select value={editingState.group} onChange={editGroup}>
                {
                  Object.keys(groups).map(key => {
                    const name = groups[key]
                    return <option key={key} value={key}>{name}</option>
                  })
                }
              </select>
            </div>
          ) : groups[user.group]
        }
      </td>
      <td className='user-row__cell user-row__cell--actions'>
        {
          editingState.editing ? (
            <>
              <button className='button is-primary' onClick={saveUser}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faSave} />
                </span>
                &nbsp;
                Save
              </button>
              &nbsp;
              <button className='button is-warning' onClick={cancelEditing}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                &nbsp;
                Cancel
              </button>
            </>
          ) : (
            <>
              <button className='button' onClick={startEditing}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faPen} />
                </span>
                &nbsp;
                Edit
              </button>
              &nbsp;
              <button className='button is-warning' onClick={startPassword}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faLock} />
                </span>
                &nbsp;
                Password
              </button>
              &nbsp;
              <button className='button is-danger' onClick={handleDelete}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faTrash} />
                </span>
                &nbsp;
                Delete
              </button>
            </>
          )
        }
        <div className={classNames('modal', {
          'is-active': passwordPopup
        })}
        >
          <div className='modal-background' onClick={cancelPassword} />
          <div className='modal-card'>
            <header className='modal-card-head'>
              <p className='modal-card-title'>Set password for user {user.name}</p>
              <button className='delete' aria-label='close' onClick={cancelPassword} />
            </header>
            <section className='modal-card-body user-row__password-prompt'>
              {passwordInput}
              <div className={classNames('user-row__password-loading', {
                'user-row__password-loading--enabled': passwordLoading
              })}
              >
                <FontAwesomeIcon icon={faSyncAlt} spin size='10x' />
              </div>
            </section>
            <footer className='modal-card-foot'>
              <button className='button is-success' disabled={!getPassword().ready || passwordLoading} onClick={savePassword}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faSave} />
                </span>
                &nbsp;
                Save password
              </button>
              &nbsp;
              <button className='button is-warning' onClick={cancelPassword}>
                <span className='icon'>
                  <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                &nbsp;
                Cancel
              </button>
            </footer>
          </div>
        </div>
        {modal}
      </td>
    </tr>
  )
}

export default UserRow
