import { combineReducers } from 'redux'

import calendar from './calendar'
import catering from './catering'
import configs from './configs'
import menu from './menu'
import orders from './orders'
import user from './user'
import users from './users'

const rootReducer = combineReducers({
  calendar,
  catering,
  configs,
  menu,
  orders,
  user,
  users
})

export default rootReducer
