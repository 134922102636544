import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import classNames from 'classnames'

import { Hero, Card } from '../containers'

import createAction from '../actions'
import { login } from '../actions/user'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faUserSecret } from '@fortawesome/free-solid-svg-icons'

class LoginPage extends React.Component {
  constructor () {
    super()

    this.handleUsername = (event) => this.props.setUsername(event.target.value)
    this.handlePassword = (event) => this.props.setPassword(event.target.value)
    this.handleEnder = (event) => {
      if (event.key === 'Enter') this.props.login()
    }

    this.state = {
      passwordVisible: false
    }

    this.usernameRef = React.createRef()
  }

  render () {
    const { username, password, error, inProgress, redirect } = this.props.loginForm

    if (this.props.loggedIn) {
      return <Redirect to={redirect || '/'} />
    }

    return (
      <Hero classes='is-fullheight login-page' innerClasses='login-page__hero-body'>
        <Card
          classes='login-page__card' image={
            <figure className='image is-2by1'>
              <img src='https://andalous-website.s3.us-east-2.amazonaws.com/image-uploads-manual/andalous-logo-500w.png' alt='' />
            </figure>
          }
        >
          <div className='field'>
            <label className='label'>Username</label>
            <div className='control'>
              <input className='input' placeholder='j0hnd03' disabled={inProgress} value={username} onChange={this.handleUsername} ref={this.usernameRef} onKeyDown={this.handleEnder} />
            </div>
          </div>

          <label className='label'>Password</label>
          <div className='field has-addons'>
            <div className='control'>
              <input
                className='input'
                type={this.state.passwordVisible ? 'text' : 'password'}
                placeholder={this.state.passwordVisible ? 'password-wow' : '••••••••••••'}
                disabled={inProgress}
                value={password}
                onChange={this.handlePassword}
                onKeyDown={this.handleEnder}
              />
            </div>
            <div className='control'>
              <button className='button' onClick={() => (this.setState({ passwordVisible: !this.state.passwordVisible }))}>
                <span className='icon'>
                  <FontAwesomeIcon icon={this.state.passwordVisible ? faUserSecret : faEye} />
                </span>
              </button>
            </div>
          </div>

          <div className='field login-page__submit'>
            <p className='help login-page__help is-danger'>{error || '\xa0'}</p>
            <div className='control login-page__submit-control'>
              <button
                className={classNames('button', {
                  'is-loading': inProgress,
                  'is-primary': !error,
                  'is-danger': error
                })} disabled={inProgress} onClick={this.props.login} // eslint-disable-line
              >Log in
              </button>
            </div>
          </div>
        </Card>
      </Hero>
    )
  }

  componentDidMount () {
    if (this.usernameRef.current && this.usernameRef.current.value === '') this.usernameRef.current.focus()
  }

  componentDidUpdate () {
    if (this.usernameRef.current && this.usernameRef.current.value === '') this.usernameRef.current.focus()
  }
}

export default connect(
  state => ({
    loginForm: state.user.loginForm,
    loggedIn: state.user.loggedIn
  }),
  dispatch => ({
    setUsername: (username) => dispatch(createAction('user.setUsername', { username })),
    setPassword: (password) => dispatch(createAction('user.setPassword', { password })),
    login: () => dispatch(login())
  })
)(LoginPage)
