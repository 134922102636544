import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { Page, withUser } from '../containers'
import { LoadingScreenWithNavbar } from '../components'

import { listPrinters, selectPrinter } from '../util/printer'

const PrinterPage = () => {
  const [printers, setPrinters] = useState({
    loaded: false,
    list: [],
    current: null
  })

  useEffect(() => {
    const ready = async () => {
      const gotPrinters = await listPrinters()

      // console.log(gotPrinters)

      setPrinters({
        loaded: true,
        list: gotPrinters.list,
        current: gotPrinters.current
      })
    }

    ready()
  }, [])

  if (!printers.loaded) return <LoadingScreenWithNavbar />

  const select = (name) => async () => {
    // console.log('selecting', name)
    const newPrinter = await selectPrinter(name)
    // console.log('selected', newPrinter)
    setPrinters({
      ...printers,
      current: newPrinter
    })
  }

  return (
    <Page>
      <div className='printer-page'>
        <h1 className='title'>Select your printer</h1>
        <div className='printer-page__printers'>
          {
            printers.list.map((printer, index) => (
              <button
                key={index} className={classNames('button is-fullwidth printer-page__button', {
                  'is-primary': printer.name === printers.current.name
                })} onClick={select(printer.name)}
              >{printer.name}
              </button>
            ))
          }
        </div>
      </div>
    </Page>
  )
}

export default withUser(PrinterPage)
