const initialState = {
  loaded: null,
  list: [],
  cursor: null,
  filters: {
    orderNumber: {
      enabled: false,
      value: 0
    },
    email: {
      enabled: false,
      value: ''
    },
    location: {
      enabled: false,
      value: ''
    }
  }
}

const cateringReducer = (state = initialState, action) => {
  const type = action.type // eslint-disable-line no-undef

  if (type === 'catering.load') return { ...state, list: action.list, cursor: action.cursor, loaded: true }
  if (type === 'catering.unload') return { ...state, list: [], loaded: false }

  if (type === 'catering.loadInfinity') {
    return {
      ...state,
      list: [...state.list, ...action.list].filter((v, i, a) => a.map(order => order.id).indexOf(v.id) === i),
      cursor: action.cursor,
      loaded: true
    }
  }

  if (type === 'catering.update-status') {
    const updatedList = state.list.map(order => {
      if (order.id !== action.id) return order

      return {
        ...order,
        status: action.status
      }
    })

    return {
      ...state,
      list: updatedList
    }
  }

  if (type === 'catering.update-config') {
    const updatedList = state.list.map(order => {
      if (order.id !== action.id) return order

      return {
        ...order,
        config: action.config
      }
    })

    return {
      ...state,
      list: updatedList
    }
  }

  if (type === 'catering.toggle-filter') {
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.filter]: {
          ...state.filters[action.filter],
          enabled: action.enabled
        }
      }
    }
  }

  if (type === 'catering.set-filter') {
    return {
      ...state,
      filters: {
        ...state.filters,
        [action.filter]: {
          ...state.filters[action.filter],
          value: action.value
        }
      }
    }
  }

  if (type === 'catering.deleteOrder') {
    return {
      ...state,
      list: state.list.filter(order => order.id !== action.id)
    }
  }

  return state
}

export default cateringReducer
