import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'

import { Hero, Container } from '../containers'
import { MenuBar } from '.'

class LoadingScreenWithNavbar extends React.Component {
  render () {
    return (
      <div className='loading-screen-with-navbar'>
        <MenuBar />
        <Hero classes='is-fullheight-with-navbar is-mobile'>
          <Container classes='has-text-centered loading-screen__text'>
            <FontAwesomeIcon icon={faSyncAlt} spin size='10x' />
          </Container>
        </Hero>
      </div>
    )
  }
}

export default LoadingScreenWithNavbar
