import config from '../config'
import createAction from '.'

export function loadMenu (resolve) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    dispatch(createAction('menu.unload'))

    const response = await window.fetch(new window.URL('/admin/ordering/items-full', config.apiUrl).href, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      console.error('unknown error', json)
      return
    }

    // console.log(json)

    dispatch(createAction('menu.load', {
      categories: json.categories,
      foods: json.foods,
      items: json.items
    }))

    if (resolve) resolve(true)
  }
}

export function updateCategory ({ id, name, description, priority }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/update-category', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id, name, description, priority
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      dispatch(loadMenu(resolve))
    }
  })

  return { action, promise }
}

export function createCategory ({ name, description, priority }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/create-category', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          name, description, priority
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      dispatch(loadMenu(resolve))
    }
  })

  return { action, promise }
}

export function deleteCategory ({ id }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/delete-category', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      dispatch(loadMenu(resolve))
    }
  })

  return { action, promise }
}

export function updateFood ({ id, name, categoryId, description, image, menuActive, featured }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/update-food', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id,
          name,
          categoryId,
          description,
          image,
          menuActive,
          featured
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadMenu((ok) => resolve({ ok })))
    }
  })

  return { action, promise }
}

export function createFood ({ name, categoryId, description, image, menuActive, featured }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/create-food', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          name,
          categoryId,
          description,
          image,
          menuActive,
          featured,
          type: 'simple',
          config: { type: 'simple' },
          price: 0
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadMenu((ok) => resolve({ ok, newId: json.id })))
    }
  })

  return { action, promise }
}

export function updatePlatter ({ id, name, categoryId, description, image, menuActive, featured, platterConfig, price }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/update-food', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id,
          name,
          categoryId,
          description,
          image,
          menuActive,
          featured,
          config: platterConfig,
          price
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadMenu((ok) => resolve({ ok })))
    }
  })

  return { action, promise }
}

export function createPlatter ({ name, categoryId, description, image, menuActive, featured, platterConfig, price }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/create-food', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          name,
          categoryId,
          description,
          image,
          menuActive,
          featured,
          config: platterConfig,
          type: platterConfig.type,
          price
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(json)
      }

      dispatch(loadMenu((ok) => resolve({ ok, newId: json.id })))
    }
  })

  return { action, promise }
}

export function deleteFood ({ id }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/delete-food', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      dispatch(loadMenu((ok) => resolve({ ok })))
    }
  })

  return { action, promise }
}

export function createItem ({ foodId, name, price, menuActive, platterActive, image }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/create-item', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          foodId, name, price, menuActive, platterActive, image
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      resolve({ ok: true, id: json.id })
    }
  })

  return { action, promise }
}

export function updateItem ({ id, foodId, name, price, menuActive, platterActive, image }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/update-item', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id, foodId, name, price, menuActive, platterActive, image
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      resolve({ ok: true, id: json.id })
    }
  })

  return { action, promise }
}

export function deleteItem ({ id }) {
  let action
  const promise = new Promise((resolve, reject) => {
    action = async (dispatch, getState) => {
      const token = getState().user.token

      const response = await window.fetch(new window.URL('/admin/delete-item', config.apiUrl).href, {
        method: 'post',
        headers: {
          'content-type': 'application/json',
          'x-user-token': token
        },
        body: JSON.stringify({
          id
        })
      })

      const json = await response.json()

      if (json.ok === false) {
        if (json.error === 'Login required') dispatch(createAction('user.logout'))

        console.error('unknown error', json)
        return resolve(false)
      }

      resolve({ ok: true, id: json.id })
    }
  })

  return { action, promise }
}
