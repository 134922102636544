import React, { useState } from 'react'
import classNames from 'classnames'
import zxcvbn from 'zxcvbn'

const usePasswordInput = () => {
  const [passwordState, setPasswordState] = useState({
    password: '',
    confirmPassword: '',
    strength: 0,
    matching: true
  })

  const resetPasswordState = (enabled) => setPasswordState({
    password: '',
    confirmPassword: '',
    strength: 0,
    matching: true
  })

  const editPassword = (event) => {
    const password = event.target.value
    const strength = Math.min(zxcvbn(password).guesses_log10, 20)
    const matching = password === passwordState.confirmPassword

    setPasswordState({ ...passwordState, password, strength, matching })
  }
  const editConfirmPassword = (event) => {
    const confirmPassword = event.target.value
    const matching = passwordState.password === confirmPassword

    setPasswordState({ ...passwordState, confirmPassword, matching })
  }

  const passwordInput = (
    <>
      <div className='field'>
        <div className='label'>Password</div>
        <div className='control'>
          <input className='input' type='password' placeholder='Password' value={passwordState.password} onChange={editPassword} />
        </div>
      </div>
      <div className='field'>
        <div className='label'>Password strength</div>
        <div className='control'>
          <progress
            className={classNames('progress', {
              'is-danger': passwordState.strength > 0 && passwordState.strength < 14,
              'is-warning': passwordState.strength >= 14 && passwordState.strength < 17,
              'is-success': passwordState.strength >= 17
            })} value={passwordState.strength} max={20}
          >{(passwordState.strength / 20).toFixed(0)}%
          </progress>
        </div>
        <p className='help is-danger'>
          {passwordState.strength > 0 && passwordState.strength < 14 ? 'The password is not strong enough' : '\u00a0'}
        </p>
      </div>
      <div className='field'>
        <div className='label'>Confirm password</div>
        <div className='control'>
          <input
            className={classNames('input', {
              'is-danger': !passwordState.matching
            })} type='password' placeholder='Confirm password' value={passwordState.confirmPassword} onChange={editConfirmPassword}
          />
        </div>
        <p className='help is-danger'>
          {!passwordState.matching ? "The passwords don't match" : '\u00a0'}
        </p>
      </div>
    </>
  )

  const getPassword = () => ({
    password: passwordState.password,
    ready: passwordState.matching && passwordState.strength >= 14,
    matching: passwordState.matching,
    strength: passwordState.strength
  })

  const resetPassword = () => resetPasswordState()

  return [passwordInput, getPassword, resetPassword]
}

export default usePasswordInput
