import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
// import bulmaCalendar from 'bulma-calendar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faCreditCard, faAngleRight, faMinus, faTrash } from '@fortawesome/free-solid-svg-icons'
import classNames from 'classnames'
import * as yup from 'yup'
import unwrap from 'async-unwrap'

import { Page, withUser } from '../../containers'
import { NumberInputManaged, useModal, DateInput } from '../../components'
import xkcd1179 from '../../util/xkcd1179'

import { crudCalendarEvent } from '../../actions/calendar'
import { loadLocations } from '../../actions/orders'

import config from '../../config'

const today = xkcd1179(new Date())

const statusStyles = {
  NEW: 'is-danger',
  VIEWED: 'is-warning',
  'CONTACT CUSTOMER': 'is-warning',
  CONTRACT: 'is-warning',
  CONFIRMED: 'is-success',
  CLOSED: 'is-light',
  CANCELLED: 'is-light'
}
const statuses = Object.keys(statusStyles)

const formatPhoneValue = (val) => {
  if (val.length < 10) return val
  const eliminationRegExp = /[\s()-]/gi
  const cleansedVal = val.replace(eliminationRegExp, '')
  const formattedVal = ['(', cleansedVal.slice(0, 3), ')', ' ', cleansedVal.slice(3, 6), '-', cleansedVal.slice(6)].join('')
  return formattedVal
}
const phoneRegExp = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
const isValidPhone = phone => yup.string()
  .required('Required')
  .matches(phoneRegExp, 'Enter a valid phone number')
  .min(10, 'Enter a valid phone number')
  .max(20, 'Enter a valid phone number')
  .isValidSync(phone)

const EditEventPage = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const history = useHistory()

  const eventList = useSelector(state => state.calendar.events.list)
  const selectedEvent = eventList.filter(event => event.id === Number(params.id))[0]

  const selectedDate = useSelector(state => state.calendar.selected.date)

  const [currentEvent, setCurrentEvent] = useState(selectedEvent || {
    id: null,
    name: '',
    date: selectedDate,
    status: 'NEW',
    config: {
      time: '1:00 AM',
      duration: 2,
      guests: 5,
      package: 'Package 1',
      location: {
        id: 1,
        name: 'Las Colinas',
        taxRate: 8.25
      },
      subtotal: 0,
      tax: 0,
      total: 0,
      taxExempt: false,
      clientEmail: '',
      clientPhone: '',
      customerComments: '',
      adminComments: '',
      payments: []
    }
  })

  const [modal, showModal] = useModal()

  const setDate = (event) => {
    setCurrentEvent({ ...currentEvent, date: xkcd1179(event.target.value) })
  }

  const [eventTime, setEventTime] = useState(currentEvent.config.time || '1:00 AM')
  const hours = Array.from(Array(12).keys(), x => x + 1).map(el => <option key={el} value={el}>{el}</option>)
  const minutes = ['00', '15', '30', '45'].map(el => <option key={el} value={el}>{el}</option>)
  const periods = ['AM', 'PM'].map(el => <option key={el} value={el}>{el}</option>)

  const handleTimeChange = ev => {
    const timePart = ev.target.id
    const timeValue = ev.target.value
    let currentHour = eventTime.split(':')[0]
    let currentMinute = eventTime.split(':')[1].split(' ')[0]
    let currentPeriod = eventTime.split(':')[1].split(' ')[1]
    if (timePart === 'timeHour') currentHour = timeValue
    if (timePart === 'timeMinute') currentMinute = timeValue
    if (timePart === 'timePeriod') currentPeriod = timeValue
    const newTime = [[currentHour, currentMinute].join(':'), currentPeriod].join(' ')
    setEventTime(newTime)
  }

  const [eventDuration, setEventDuration] = useState(currentEvent.config.duration || 0)
  const durations = Array.from(Array(9).keys(), x => x + 2).map(el => <option key={el} value={el}>{el}</option>)

  const setName = (event) => setCurrentEvent({ ...currentEvent, name: event.target.value })
  const setStatus = (status) => (event) => setCurrentEvent({ ...currentEvent, status })
  const setConfigField = (field, postprocess, subfield) => (event) => {
    const isCheckbox = event.target.tagName === 'INPUT' && event.target.type === 'checkbox'
    const value = isCheckbox ? event.target.checked : event.target.value

    const newCurrentEvent = {
      ...currentEvent,
      config: {
        ...currentEvent.config,
        [field]: !subfield ? value : {
          ...currentEvent.config[field],
          [subfield]: value
        }
      }
    }

    if (postprocess) {
      const taxRate = newCurrentEvent.config.taxExempt ? 0 : newCurrentEvent.config.location.taxRate
      const subtotal = Number(newCurrentEvent.config.subtotal)
      const tax = subtotal * taxRate / 100

      newCurrentEvent.config.tax = Number((tax).toFixed(2))
      newCurrentEvent.config.total = Number((tax + subtotal).toFixed(2))
    }

    setCurrentEvent(newCurrentEvent)
  }

  // const setCheckbox = (name) => (event) => setCurrentEvent({ ...currentEvent, [name]: !currentEvent[name] })

  const isNew = params.id === 'new'

  const handleDelete = async () => {
    const sure = await showModal(
      'Delete Event',
      `Are you sure you want to delete event '${currentEvent.name}' from your calendar?`,
      [{
        text: 'No, continue editing',
        response: 'no',
        isCancel: true
      }, {
        text: 'Yes, delete event',
        className: 'is-danger',
        response: 'yes'
      }]
    )

    if (sure !== 'yes') return

    const { promise, action } = crudCalendarEvent({ event: { id: currentEvent.id }, method: 'delete' })
    dispatch(action)
    const result = await promise

    if (!result.ok) {
      await showModal('Error While Deleting Event', `Error: ${result.error || 'Unknown error'}`, [{ text: 'OK' }])
    } else {
      history.push('/events')
    }
  }
  const cancel = async () => {
    const sure = await showModal(
      isNew ? 'Cancel New Event' : 'Cancel Edit Event',
      'Are you sure you want to discard your changes?',
      [{
        text: 'No, continue editing',
        response: 'no',
        isCancel: true
      }, {
        text: 'Yes, discard changes',
        className: 'is-danger',
        response: 'yes'
      }]
    )

    if (sure !== 'yes') return

    history.push('/events')
  }
  const submit = async () => {
    const sure = await showModal(
      isNew ? 'Add New Event' : 'Edit Event',
      'Would you like to save your changes?',
      [{
        text: 'No, continue editing',
        response: 'no',
        isCancel: true
      }, {
        text: 'Yes, save changes',
        className: 'is-success',
        response: 'yes'
      }]
    )

    if (sure !== 'yes') return

    const eventToSave = { ...currentEvent }
    if (isNew) delete eventToSave.id

    delete eventToSave.createdAt
    delete eventToSave.fullDate

    eventToSave.config.taxExempt = Boolean(eventToSave.config.taxExempt)
    eventToSave.config.payments = eventToSave.config.payments || []
    eventToSave.config.time = eventTime
    eventToSave.config.duration = eventDuration
    eventToSave.date = eventToSave.date.replace(/\//g, '-')

    // console.log(eventToSave)

    const { promise, action } = crudCalendarEvent({ event: eventToSave, method: isNew ? 'create' : 'update' })
    dispatch(action)
    const result = await promise

    if (!result.ok) {
      await showModal('Error While Saving Event', `Error: ${result.error || 'Unknown error'}`, [{ text: 'OK' }])
    } else {
      history.push('/events')
    }
  }

  const token = useSelector(state => state.user.token)
  const [sendingContract, setSendingContract] = useState(false)

  const sendContractEmail = async () => {
    const response = await showModal(
      'Send Contract Email',
      'Are you sure you would like to send the contract email for this order?',
      [{
        text: 'Yes',
        className: 'is-success',
        response: 'yes'
      }, {
        text: 'No',
        className: 'is-danger',
        isCancel: true
      }]
    )

    if (response !== 'yes') return

    setSendingContract(true)

    const [error] = await window.fetch(new window.URL('/admin/send-calendar-contract', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id: Number(params.id)
      })
    })[unwrap]

    if (error) {
      await showModal(
        'Error while Sending Contract Email',
        error.message
      )
    } else {
      await showModal(
        'Send Contract Email',
        'Email has been sent successfully'
      )
    }

    setSendingContract(false)
  }

  const logPayment = async () => {
    const payment = {
      amount: 0,
      date: today,
      type: 'Credit Card'
    }

    const sure = await showModal(
      'Log Payment',
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Amount</label>
            <NumberInputManaged defaultValue={0} onChange={event => (payment.amount = event.target.value)} buttonsHidden />
          </div>
          <div className='field'>
            <label className='label'>Type</label>
            <div className='select'>
              <select defaultValue='Credit Card' onChange={event => (payment.type = event.target.value)}>
                <option value='Credit Card'>Credit Card</option>
                <option value='Cash'>Cash</option>
                <option value='Check'>Check</option>
              </select>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Date</label>
            <DateInput defaultValue={currentEvent.date} inline onChange={event => (payment.date = xkcd1179(event.target.value))} />
          </div>
        </div>
      </div>,
      [{
        text: 'Cancel',
        isCancel: true,
        response: 'no'
      }, {
        text: 'Log Payment',
        className: 'is-success',
        response: 'yes'
      }]
    )

    if (sure !== 'yes') return

    const payments = [
      ...currentEvent.config.payments,
      payment
    ]

    setCurrentEvent({
      ...currentEvent,
      config: {
        ...currentEvent.config,
        payments
      }
    })
  }

  const removePayment = (index) => () => {
    const payments = [...currentEvent.config.payments]
    payments.splice(index, 1)

    setCurrentEvent({
      ...currentEvent,
      config: {
        ...currentEvent.config,
        payments
      }
    })
  }

  const loaded = useSelector(state => state.orders.locations.loaded)
  const locations = useSelector(state => state.orders.locations.list)

  useEffect(() => {
    if (!loaded) dispatch(loadLocations())
  }, [loaded, dispatch])

  return (
    <Page>
      <div className='level'>
        <div className='level-left'>
          <h1 className='title'>{isNew ? 'Add new event' : 'Edit event'}</h1>
        </div>
        <div className='level-right'>
          {/* <button className='button' onClick={cancel}>Cancel</button> */}
        </div>
      </div>
      <div className='field'>
        <label className='label'>Name</label>
        <div className='control'>
          <input className='input' value={currentEvent.name} onChange={setName} />
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Date</label>
            <div className='control'>
              <DateInput value={currentEvent.date} onChange={setDate} />
            </div>
          </div>
        </div>
        <div className='column'>
          <label className='label'>Time</label>
          <div className='field has-addons'>
            <div className='control'>
              <div className='select'>
                <select name='hour-select' id='timeHour' onChange={ev => handleTimeChange(ev)} value={eventTime.split(':')[0]}>
                  {hours}
                </select>
              </div>
            </div>
            <div className='control'>
              <div className='select'>
                <select name='minute-select' id='timeMinute' onChange={ev => handleTimeChange(ev)} value={eventTime.split(':')[1].split(' ')[0]}>
                  {minutes}
                </select>
              </div>
            </div>
            <div className='control'>
              <div className='select'>
                <select name='period-select' id='timePeriod' onChange={ev => handleTimeChange(ev)} value={eventTime.split(' ')[1]}>
                  {periods}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Duration (in hours)</label>
            <div className='control'>
              <div className='select'>
                <select value={eventDuration} onChange={ev => setEventDuration(ev.target.value)}>
                  <option value='0' key='0'>0</option>
                  {durations}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Guests</label>
            <div className='control'>
              <NumberInputManaged value={currentEvent.config.guests} onChange={setConfigField('guests')} />
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Location</label>
            <div className='control'>
              {/* <input className='input' value={currentEvent.config.location.name} onChange={setConfigField('location', false, 'name')} /> */}
              <div className='select is-fullwidth'>
                <select value={currentEvent.config.location.name} onChange={setConfigField('location', false, 'name')}>
                  {
                    locations.map(location => <option key={location.id} value={location.name}>{location.name}</option>)
                  }
                </select>
              </div>
            </div>
          </div>
          <div className='field level'>
            <div className='level-left'>
              <label className='label'>Local Tax Rate</label>
            </div>
            <div className='level-right'>
              <div className='control'>
                <NumberInputManaged small value={currentEvent.config.location.taxRate} onChange={setConfigField('location', true, 'taxRate')} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='field'>
        <label className='label'>Status</label>
        <div className='field edit-event-page__status-row'>
          {
            statuses.map((status, index, array) => {
              const selected = currentEvent.status === status
              const isCaret = index < array.length - 2
              const isLine = index === array.length - 2

              return (
                <React.Fragment key={status}>
                  <button
                    className={classNames('button is-small edit-event-page__status-button', {
                    // [statusStyles[status]]: selected || statusStyles[status] !== 'is-dark',
                      [statusStyles[status]]: selected,
                      // 'is-outlined': !selected
                      'is-light': !selected,
                      'has-text-weight-bold': true
                    })} onClick={setStatus(status)}
                  >
                    {status}
                  </button>
                  {!isCaret ? '' : <FontAwesomeIcon className='edit-event-page__status-separator' icon={faAngleRight} />}
                  {!isLine ? '' : <FontAwesomeIcon className='edit-event-page__status-separator' icon={faMinus} rotation={90} />}
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Email</label>
            <div className='control'>
              <input className='input' value={currentEvent.config.clientEmail} onChange={setConfigField('clientEmail')} />
            </div>
            <p className='help is-danger'>
              {
                yup.string().email().min(1).isValidSync(currentEvent.config.clientEmail) ? undefined : 'invalid email'
              }
            </p>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Phone</label>
            <div className='control'>
              <input className='input' value={currentEvent.config.clientPhone} onChange={(event) => setConfigField('clientPhone')({ target: { value: formatPhoneValue(event.target.value) } })} />
            </div>
            <p className='help is-danger'> {/* gonna tell you a secret: this is no help */}
              {
                isValidPhone(currentEvent.config.clientPhone) ? undefined : 'invalid phone'
              }
            </p>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Package</label>
            <div className='control'>
              <input className='input' value={currentEvent.config.package} onChange={setConfigField('package')} />
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        {
          isNew ? undefined : (
            <div className='column'>
              <div className='field'>
                <label className='label'>Customer comments</label>
                <div className='control'>
                  <textarea className='textarea has-fixed-size is-light' rows={6} value={currentEvent.config.customerComments} readOnly />
                </div>
              </div>
            </div>
          )
        }
        <div className='column'>
          <div className='field'>
            <label className='label'>Admin comments (private)</label>
            <div className='control'>
              <textarea className='textarea has-fixed-size' rows={6} value={currentEvent.config.adminComments} onChange={setConfigField('adminComments')} />
            </div>
          </div>
        </div>
      </div>
      <div className='columns'>
        <div className='column'>
          <div className='field'>
            <label className='label'>Subtotal</label>
            <div className='control'>
              <NumberInputManaged value={currentEvent.config.subtotal} onChange={setConfigField('subtotal', true)} buttonsHidden />
            </div>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Tax</label>
            <div className='control'>
              <input className='input is-light has-text-grey' value={currentEvent.config.tax.toFixed(2)} readOnly />
            </div>
          </div>
          <div className='field'>
            <label className='checkbox'>
              <input type='checkbox' checked={Boolean(currentEvent.config.taxExempt)} onChange={setConfigField('taxExempt', true)} />
              {' '}
              Tax exempt
            </label>
          </div>
        </div>
        <div className='column'>
          <div className='field'>
            <label className='label'>Total</label>
            <div className='control'>
              <input className='input is-light has-text-weight-bold' value={`$${currentEvent.config.total.toFixed(2)}`} readOnly />
            </div>
          </div>
        </div>
      </div>
      {
        isNew ? undefined : (
          <div className='columns'>
            <div className='column'>
              <div className='field'>
                <label className='label'>Payments Received:</label>
                <table className='table is-striped is-fullwidth is-hoverable'>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Type</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (currentEvent.config.payments || []).map((payment, index) => (
                        <tr key={index}>
                          <td>{new Date(payment.date).toLocaleDateString('en-us', { year: 'numeric', day: 'numeric', month: 'short', timeZone: 'utc' })}</td>
                          <td>${payment.amount}</td>
                          <td>{payment.type || 'Unknown'}</td>
                          <td className='has-text-right'>
                            <button className='button is-small' onClick={removePayment(index)}>
                              <span className='icon'>
                                <FontAwesomeIcon icon={faTrash} />
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </div>
            </div>
            <div className='column'>
              <div className='field'>
                <label className='label'>Actions</label>
                <div className='columns edit-event-page__action-row'>
                  <div className='column'>
                    <button className='button' onClick={logPayment}>
                      <span className='icon'>
                        <FontAwesomeIcon icon={faCreditCard} />
                      </span>
                      <span>
                        Log Payment
                      </span>
                    </button>
                  </div>
                  <div className='column'>
                    <button
                      className={classNames('button', {
                        'is-loading': sendingContract
                      })} disabled={sendingContract} onClick={sendContractEmail}
                    >
                      <span className='icon'>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </span>
                      <span>
                        Send Contract Email
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
      <div className='is-divider' />
      <div className='level'>
        <div className='level-left'>
          {
            isNew
              ? undefined
              : (
                <>
                  <div className='field is-grouped'>
                    <p className='control'>
                      <button className='button is-danger' onClick={handleDelete}>Delete</button>
                    </p>
                  </div>
                </>
              )
          }
        </div>
        <div className='level-right'>
          <div className='field is-grouped is-grouped-right'>
            <p className='control'>
              <button className='button' onClick={cancel}>Cancel</button>
            </p>
            <p className='control'>
              <button
                className='button is-primary' onClick={submit} disabled={
                  !yup.string().email().min(1).isValidSync(currentEvent.config.clientEmail) ||
                !isValidPhone(currentEvent.config.clientPhone)
                }
              >Submit
              </button>
            </p>
          </div>
        </div>
      </div>
      {modal}
    </Page>
  )
}

export default withUser(EditEventPage)
