import React from 'react'
import classNames from 'classnames'

class ModalCard extends React.Component {
  constructor () {
    super()

    this.state = {
      active: false
    }

    this.open = () => this.setState({ active: true })
    this.close = () => this.setState({ active: false })

    this.backgroundRef = React.createRef()
    this.handleBackgroundClick = (event) => {
      if (event.target === this.backgroundRef.current) this.close()
    }
  }

  render () {
    return (
      <div className={classNames('modal', {
        'is-active': this.state.active
      })}
      >
        <div className='modal-background' onClick={this.handleBackgroundClick} ref={this.backgroundRef} />
        <div className={classNames('modal-card', this.props.classes)}>
          {
            <div className={classNames('modal-card-head', this.props.headClasses)}>
              <div className={classNames('modal-card-title', this.props.titleClasses)}>
                {this.props.title}
              </div>
              <button className='delete' aria-label='close' onClick={this.close} /> {/* eslint-disable-line */}
            </div>
          }
          <div className={classNames('modal-card-body', this.props.innerClasses)}>
            {this.props.children}
          </div>
          {
            <div className={classNames('modal-card-foot', this.props.footClasses)}>
              {this.props.foot}
            </div>
          }
        </div>
      </div>
    )
  }
}

export default ModalCard
