import querystring from 'querystring'

import config from '../config'
import createAction from '.'

export function loadOrders (cursor) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    if (!cursor) dispatch(createAction('orders.unload'))

    const params = {
      confirmedOnly: true,
      limit: config.ordersPerPage,
      includeEvents: true
    }
    if (cursor) params.cursor = cursor
    const response = await window.fetch(new window.URL(`/admin/ordering/list-orders?${querystring.stringify(params)}`, config.apiUrl).href, {
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      // console.error('unknown error', json)
      return
    }

    // console.log(json)

    if (cursor) dispatch(createAction('orders.loadInfinity', { list: json.orders, cursor: json.cursor }))
    else dispatch(createAction('orders.load', { list: json.orders, cursor: json.cursor }))
  }
}

export function completeOrder (id) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    // dispatch(createAction('orders.unload'))

    const response = await window.fetch(new window.URL('/admin/ordering/complete-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id
      })
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      console.error('unknown error', json)
      // return
    }

    // console.log(json)

    // dispatch(loadOrders())
  }
}

export function markOrderAsPrinted (id) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    // dispatch(createAction('orders.unload'))

    const response = await window.fetch(new window.URL('/admin/ordering/print-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id
      })
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      console.error('unknown error', json)
      // return
    }

    // console.log(json)

    // dispatch(loadOrders())
  }
}

export function markOrder (id, status) {
  return async (dispatch, getState) => {
    const token = getState().user.token

    // dispatch(createAction('orders.unload'))

    const response = await window.fetch(new window.URL('/admin/ordering/mark-order', config.apiUrl).href, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      },
      body: JSON.stringify({
        id,
        status
      })
    })
    const json = await response.json()

    if (json.ok === false) {
      if (json.error === 'Login required') dispatch(createAction('user.logout'))

      console.error('unknown error', json)
      // return
    }

    // console.log(json)

    // dispatch(loadOrders())
  }
}

export function loadLocations () {
  return async (dispatch, getState) => {
    const token = getState().user.token

    dispatch(createAction('orders.unloadLocations'))

    const result = await window.fetch(new window.URL('/admin/ordering/locations', config.apiUrl).href, {
      headers: {
        'content-type': 'application/json',
        'x-user-token': token
      }
    })
    const list = await result.json()

    if (list.ok !== false) {
      dispatch(createAction('orders.loadLocations', { locations: list }))
    } else {
      console.error(`Failed to load locations: ${list.error}`)
    }
  }
}
