import React from 'react'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { LoadingScreen } from '../components'
import config from '../config'

import createAction from '../actions'
import { testLogin } from '../actions/user'

const withUser = (Component) => {
  function WithUser (props) {
    const targetPath = props.location.pathname

    if (!props.withUser__loggedIn) {
      props.withUser__setRedirect(targetPath)
      return <Redirect to='/login' />
    }

    const sinceLastTested = new Date().getTime() - props.withUser__lastTested
    const needsTest = sinceLastTested > config.loginKeepAlive * 1000

    if (needsTest) {
      props.withUser__testLogin()
      return <LoadingScreen />
    }

    const isPrivileged = props.withUser__group === 'admin'

    if (!isPrivileged) {
      const allowedPath = targetPath.match(/^\/(kitchen|printer|events|edit\/event).*$/)
      if (!allowedPath) return <Redirect to='/kitchen' />
    }

    return <Component {...props} />
  }

  return connect(
    state => ({
      withUser__loggedIn: state.user.loggedIn,
      withUser__lastTested: state.user.lastTested,
      withUser__group: state.user.group
    }),
    dispatch => ({
      withUser__setRedirect: (redirect) => dispatch(createAction('user.setLoginRedirect', { redirect })),
      withUser__testLogin: () => dispatch(testLogin())
    })
  )(withRouter(WithUser))
}

export default withUser
