import config from '../config'
import createAction from '../actions'

const imageFromFile = (file) => new Promise((resolve, reject) => {
  const image = new window.Image()

  image.onload = () => resolve(image)
  image.onerror = reject

  image.src = URL.createObjectURL(file)
})

const uploadImage = async (file, token, dispatch) => {
  const image = await imageFromFile(file)
  if (image.width !== 600 || image.height !== 600) throw new Error('Image must be 600x600')

  const data = new window.FormData()
  data.append('image', file)

  const response = await window.fetch(new window.URL('/admin/upload', config.apiUrl).href, {
    method: 'post',
    headers: {
      'x-user-token': token
    },
    body: data
  })

  const json = await response.json()

  if (json.ok === false) {
    if (json.error === 'Login required') dispatch(createAction('user.logout'))

    console.error('unknown error', json)
    throw new Error('unknown error')
  }

  return json
}

export default uploadImage
